
const CONFIG = {
  COMMON: {
    APP_TITLE: '合规地图管理系统'
  },
  development: {
    uidsBaseUrl : 'https://shsms-tst.roche.com.cn/uids',//UIDS 后台地址
    appId :'10070',
    apiBaseUrl : 'https://apps.roche.com.cn:442/',//后台API地址
    gigyaKey:'4_kCvqXRnfIn23bpPlMWOxgQ',
    localUrl:'http://localhost:8080/'
  },        
  uat: {
    uidsBaseUrl : 'https://shsms-tst.roche.com.cn/uids',//UIDS 后台地址
    appId : '10070',
    apiBaseUrl : 'https://apps.roche.com.cn:442/',//后台API地址
    gigyaKey:'4_kCvqXRnfIn23bpPlMWOxgQ',
    localUrl:'https://roche-smallapps-portal-noprod.roche.com.cn/'
  },
  production: {
    uidsBaseUrl : 'https://shsms.roche.com.cn/uids',//UIDS 后台地址
    // uidsBaseUrl : 'https://apps.roche.com.cn:443/uids',//UIDS 后台地址
    appId : '10070',
    apiBaseUrl : 'https://apps.roche.com.cn:443/',//后台API地址
    gigyaKey:'4_kCvqXRnfIn23bpPlMWOxgQ',
    localUrl:'https://compliancemap.roche.com.cn/'
  }
}

const getEnvConfig = () => {
  const ENV = process.env.NODE_ENV || 'development'
  const commonConfig = CONFIG.COMMON
  const envConfig = CONFIG[ENV]
  return { ...commonConfig, ...envConfig }
}

module.exports = {
  getEnvConfig
}
