// import { message } from 'ant-design-vue';
import axios from 'axios'
import { VueAxios } from './axios'
import { getUrlParameter, getCode } from '@/utils/util.js'
import { getGigyaToken,clearCache, redirectToLogin,setstatus} from '@/utils/auth'
import store from '@/store'
import router from '@/router'
// import { getToken } from '@/utils/util'
// 
// const prefix = '/api'
const flag = getUrlParameter("flag")
// 创建 axios 实例
const request = axios.create({
  // API 请求的默认前缀
  baseURL: process.env.VUE_APP_BASE_API ,
 // 超时
  timeout: 300000
})

const robotRequest = axios.create({
  // API 请求的默认前缀
  baseURL: process.env.VUE_APP_BASE_API ,
  timeout: 0 // 请求超时时间
})

// const robotRequest = axios.create({
//   // API 请求的默认前缀
//   baseURL: process.env.VUE_APP_API_ROBOT_API,
//   timeout: 0 // 请求超时时间
// })

// 异常拦截处理器
const errorHandler = async(error) => {
  const { response } = error

  if (response && response.status && response.config) {
    const { status, config } = response
    if (!config.ignore && status === 401) {
      // getCode()
      if(flag){
        clearCache()
        getCode()
      }else{
        clearCache()
        redirectToLogin()
        gigya.socialize.addEventHandlers({
          onLogin: async function(response) {          
            if (response.user.isLoggedIn) {
              await store.dispatch('login', JSON.stringify(response))
              setstatus("401")
              router.replace({path:"/home"})
              location.reload()
            }
          }
        })
    }     
    }
  }

  return Promise.reject(response)
}

// request interceptor
request.interceptors.request.use((config) => {
   // let token = getUrlParameter("dpjwt") || "eyJhbGciOiJIUzUxMiJ9.eyJsb2dpbl91c2VyX2tleSI6IjJkODcwZjU5LTc1MzYtNGE3ZC05NWMwLWMxMzMzZDdkNmI2ZiJ9.xPQTp0waGQWJzm-64Vbcl03tsOkBV6vxh_S1mlitFtQbf2QzCt7UJn3xlk5KEaLbPMbHRfrArVIo47xa84i6qw"
  // let token = getUrlParameter("dpjwt") 
  // if (token) {
  //   let reg = new RegExp("#/home$","g")
  //   token = token.replace(reg,"")
  //   localStorage.setItem("rocheMap_token", token)
  //   config.headers['Authorization'] = `Bearer ${token}`
  // }else{
  //   let dpjwt = localStorage.getItem("rocheMap_token")
  //   if(dpjwt){
  //     config.headers['Authorization'] = `Bearer ${dpjwt}`
  //   }else{
  //      // location.href = "https://portal-uat.roche.com.cn/"
  //      if(config.url.indexOf('?')>0){
  //         config.url += `&_time=${new Date().getTime()}`
  //       } else {
  //         config.url += `?_time=${new Date().getTime()}`
  //       } 
  //       // const appToken = getAppToken()
  //       const GigyaAuthToken = getGigyaToken()
  //       //const MuteAuthentication = getXAUTHToken()
  //       //const Authorization = getUserInfo()
  //       //config.headers['X-AUTH-TOKEN'] = `${MuteAuthentication}`
  //       // config.headers['GIGYAAUTHTOKEN'] = `${GigyaAuthToken}` 
  //       if(GigyaAuthToken){
  //          config.headers['Authorization'] = `Bearer ${GigyaAuthToken}`
  //       }else{
  //         redirectToLogin()
  //       }
  //   }
  // }
    if(config.url.indexOf('?')>0){
      config.url += `&_time=${new Date().getTime()}`
    } else {
      config.url += `?_time=${new Date().getTime()}`
    } 
    const GigyaAuthToken = getGigyaToken()  
    config.headers['Authorization'] = `Bearer ${GigyaAuthToken}`    
    config.headers['Content-Type'] = 'application/json;charset=UTF-8'

  return config
}, errorHandler)


// response interceptor
request.interceptors.response.use((response) => {
  if(response &&  response.data && response.data.code && response.data.code == "401"){    
      // clearCache()
      // getCode()
      if(flag){
        // message.error('UIDS token无效，请刷新重试');
        console.log('UIDS token无效，请刷新重试')
        clearCache()
        getCode()
      }else{
        // message.error('Gigya token无效，请刷新重试');
         console.log('Gigya token无效，请刷新重试')
        clearCache()
        redirectToLogin()
       gigya.hasSession().then(function (sessionExist) {
                console.log("session exists:" + sessionExist);
                if (sessionExist) {
                    gigya.sso.continue();
                } else {
                    showLogin();
                    gigya.accounts.addEventHandlers({
                       onLogin: async function(response) {          
                        if (response.user.isLoggedIn) {
                          await store.dispatch('login', JSON.stringify(response))
                          setstatus("401")
                          router.replace({path:"/home"})
                          location.reload()
                        }
                      }
                    })
                  }
                })
    }     
       // router.push({name:"home"})
       // location.reload()
  }
  return response
}, errorHandler)

// request interceptor
robotRequest.interceptors.request.use((config) => {
   // let token = getUrlParameter("dpjwt") || "eyJhbGciOiJIUzUxMiJ9.eyJsb2dpbl91c2VyX2tleSI6IjJkODcwZjU5LTc1MzYtNGE3ZC05NWMwLWMxMzMzZDdkNmI2ZiJ9.xPQTp0waGQWJzm-64Vbcl03tsOkBV6vxh_S1mlitFtQbf2QzCt7UJn3xlk5KEaLbPMbHRfrArVIo47xa84i6qw"
  // let token = getUrlParameter("dpjwt") 
  // if (token) {
  //   let reg = new RegExp("#/home$","g")
  //   token = token.replace(reg,"")
  //   localStorage.setItem("rocheMap_token", token)
  //   config.headers['Authorization'] = `Bearer ${token}`
  // }else{
  //   let dpjwt = localStorage.getItem("rocheMap_token")
  //   if(dpjwt){
  //     config.headers['Authorization'] = `Bearer ${dpjwt}`
  //   }else{
  //      location.href = "https://portal-uat.roche.com.cn/"
  //   }
  // }
  // config.headers['Content-Type'] = 'application/json;charset=UTF-8'

  // return config
   if(config.url.indexOf('?')>0){
      config.url += `&_time=${new Date().getTime()}`
    } else {
      config.url += `?_time=${new Date().getTime()}`
    } 
    // const appToken = getAppToken()
    const GigyaAuthToken = getGigyaToken()
    //const MuteAuthentication = getXAUTHToken()
    //const Authorization = getUserInfo()
    //config.headers['X-AUTH-TOKEN'] = `${MuteAuthentication}`
    // config.headers['GIGYAAUTHTOKEN'] = `${GigyaAuthToken}` 
    config.headers['Authorization'] = `Bearer ${GigyaAuthToken}`
    //config.headers['Content-Type'] = `application/json;charset=utf-8` 
    config.headers['Content-Type'] = 'application/json;charset=UTF-8'

  return config
}, errorHandler)

// response interceptor
robotRequest.interceptors.response.use((response) => {
  if(response &&  response.data && response.data.code && response.data.code == "401"){
       if(flag){
        // message.error('UIDS token无效，请刷新重试');
        console.log('UIDS token无效，请刷新重试')
        clearCache()
        getCode()
      }else{
        // message.error('Gigya token无效，请刷新重试');
        console.log('Gigya token无效，请刷新重试')
        clearCache()
        redirectToLogin()
        gigya.socialize.addEventHandlers({
          onLogin: async function(response) {          
            if (response.user.isLoggedIn) {
              await store.dispatch('login', JSON.stringify(response))
              setstatus("401")
              router.replace({path:"/home"})
              location.reload()
            }
          }
        })
    }     
  }
  return response
}, errorHandler)

const installer = {
  vm: {},
  install(Vue) {
    Vue.use(VueAxios, request, robotRequest)
  }
}

export default request

export {
  installer as VueAxios,
  request as axios,
  robotRequest
}
