<template>
    <!-- 弹出框 -->
    <div id="TrainDialog" class="b-modal">
        <div class="modal-inner">
            <h2 class="m-header" data-i18n="Training.head">
                {{ $t('Training.head') }} <span class="trainingTitle">{{ trainName }}</span>
            </h2>
            <div class="m-body">
                {{ $t('Dialog.tips') }}
                <a-row class="flexRow">
                    <a-col :span="6" v-for="item in toal" :key="item" class="flexItems">
                        <div class="picItem">
                            <a-avatar v-if="trainList[item - 1].pos" class="pic"
                                :src="require(`../../assets/img/${trainList[item - 1].pos}.png`)" size="large" />
                            <div class="ellipsis-text">{{ $t(trainList[item - 1].id + '.head') }}</div>
                        </div>
                        <Lottie v-if="item != toal" :options="defaultOptions" class="animation" />
                    </a-col>
                </a-row>
                <p style="text-align: center;" class="btnDisplay">
                    <button @click="completeTrain" data-fancybox-close
                        style="width:100px;height:30px;line-height:30px;background:#5d3a10;color:#fff;border-radius: 10px;border:1px solid #5d3a10;"
                        data-i18n="Training.button"> {{ $t('Training.button') }} </button>
                </p>
            </div>
        </div>
    </div>
</template>

<script>

import Lottie from 'vue-lottie/src/lottie.vue'
import * as anData from '@/assets/lottie/right_arrow.json'

export default ({
    name: "successCompleteDiag",
    data() {
        return {
            marginWidth: 0,
            defaultOptions: {
                animationData: anData.default
            },
        }
    },
    components: { Lottie },
    computed: {
        size() {
            return 4
        }
    },
    props: {
        trainName: {
            type: String,
            default: 'XXX培训'
        },
        toal: {
            type: [Number, String],
            default: '0'
        },
        trainList: {
            type: Array,
            default: []
        }
    },
    computed: {
    },
    created() {
        let that = this;
    },
    mounted() {

    },
    methods: {
        completeTrain() {
            this.$emit("initTrain")
        },
        getZindex(item) {
            if (item == this.current) {
                return 100
            } else {
                return 100 - item
            }
        }
    },
    watch: {

    },
})
</script>

<style lang="scss" scoped>
.animation {
    height: 20vh;
    width: auto;
}

.lottieAnimate {
    margin-top: -10vh !important;
}

#TrainDialog .m-header{
    width: 100%
}
.m-body {}

.m-title {
    text-align: center;
}

.trainingTitle {
    text-align: center;
    font-weight: bold;

    position: relative;
    /* 设置立体
    text-transform: uppercase;
    text-shadow:
        0px 1px 0px #c0c0c0,
        0px 2px 0px #b0b0b0,
        0px 3px 0px #a0a0a0,
        0px 4px 0px #909090,
        0px 5px 10px rgba(0, 0, 0, 0.6);
    */  
}

.btnDisplay {
    margin-top: 1vh;
    position: absolute;
    bottom: 2vh;
    left: 50%;
    transform: translateX(-50%) !important;
}

.flexRow {
    width: 100%;
    padding: 3vh 0;

    .flexItems {
        display: flex;

        .picItem {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 9vh;

            .pic {
                width: 9vh;
                height: 9vh;
            }

            :deep(img) {
                border: solid white 1px;
                padding: 1vh;
                background: #fae0b7;
                object-fit: contain;
            }

            .ellipsis-text {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-size: 1.6vh;
                line-height: 4.2vh;
                width: 100%;
                text-align: center;
            }
        }

        .animation {
            width: 9vh !important;
        }
    }
}
</style>
