export default {
    "msg": "操作成功",
    "code": 200,
    "data": [{
        "searchValue": null,
        "createBy": null,
        "createTime": null,
        "updateBy": null,
        "updateTime": null,
        "remark": null,
        "params": {},
        "id": 57,
        "planname": "培训计划_417",
        "plandetailsjson": null,
        "plandetails": [
            {
                "id": "Approval",
                "name": " \uD83C\uDF85材料审批",
                "pid": null,
                "isfinish": true,
                "children": [
                    {
                        "isfinish": true,
                        "id": "105",
                        "name": "非推广材料审批SOP",
                        "pid": "Approval",
                        "children": null
                    },
                    {
                        "isfinish": true,
                        "id": "106",
                        "name": "不良事件报告相关问题",
                        "pid": "Approval",
                        "children": null
                    },
                ]
            },
            {
                "isfinish": true,
                "id": "Hospital",
                "name": "活动与会议",
                "pid": null,
                "children": [
                    {
                        "isfinish": false,
                        "id": "138",
                        "name": "会议和活动政策速览",
                        "pid": "Hospital",
                        "children": null
                    },
                    {
                        "isfinish": false,
                        "id": "139",
                        "name": "会议-主席",
                        "pid": "Hospital",
                        "children": null
                    },
                    {
                        "isfinish": false,
                        "id": "140",
                        "name": "签到表",
                        "pid": "Hospital",
                        "children": null
                    },
                    {
                        "isfinish": false,
                        "id": "141",
                        "name": "会议议程",
                        "pid": "Hospital",
                        "children": null
                    },
                    {
                        "isfinish": false,
                        "id": "142",
                        "name": "会议地点选择标准",
                        "pid": "Hospital",
                        "children": null
                    },
                    {
                        "isfinish": false,
                        "id": "143",
                        "name": "履行证明",
                        "pid": "Hospital",
                        "children": null
                    },
                    {
                        "isfinish": false,
                        "id": "144",
                        "name": "I-simple",
                        "pid": "Hospital",
                        "children": null
                    },
                    {
                        "isfinish": false,
                        "id": "145",
                        "name": "讲者相关文件",
                        "pid": "Hospital",
                        "children": null
                    },
                    {
                        "isfinish": false,
                        "id": "146",
                        "name": "避免使用高风险商户",
                        "pid": "Hospital",
                        "children": null
                    },
                    {
                        "isfinish": false,
                        "id": "147",
                        "name": "飞检培训材料",
                        "pid": "Hospital",
                        "children": null
                    },
                    {
                        "isfinish": false,
                        "id": "148",
                        "name": "常见问题",
                        "pid": "Hospital",
                        "children": null
                    },
                    {
                        "isfinish": false,
                        "id": "149",
                        "name": "最新动态",
                        "pid": "Hospital",
                        "children": null
                    },
                    {
                        "isfinish": false,
                        "id": "150",
                        "name": "更多文件",
                        "pid": "Hospital",
                        "children": null
                    }
                ]
            },
            {
                "id": "Meetings",
                "name": "采购/供应商系统及流程",
                "pid": null,
                "isfinish": true,
                "children": [
                    {
                        "isfinish": true,
                        "id": "84",
                        "name": "采购相关查询方式",
                        "pid": "Meetings",
                        "children": null
                    },
                    {
                        "isfinish": true,
                        "id": "85",
                        "name": "供应商查询系统",
                        "pid": "Meetings",
                        "children": null
                    },
                    {
                        "isfinish": true,
                        "id": "87",
                        "name": "新建供应商",
                        "pid": "Meetings",
                        "children": null
                    },
                    {
                        "isfinish": true,
                        "id": "88",
                        "name": "采购系统操作手册",
                        "pid": "Meetings",
                        "children": null
                    },
                    {
                        "isfinish": true,
                        "id": "89",
                        "name": "供应商Commodity Code",
                        "pid": "Meetings",
                        "children": null
                    }
                ]
            },
            {
                "id": "Nogo",
                "name": "高风险商户",
                "pid": null,
                "isfinish": false,
                "children": [
                    {
                        "id": "78",
                        "isfinish": false,
                        "name": "酒店选择原则-避免使用奢华酒店列表",
                        "pid": "Nogo",
                        "children": null
                    },
                    {
                        "isfinish": false,
                        "id": "79",
                        "name": "No-go Venue高风险商户",
                        "pid": "Nogo",
                        "children": null
                    },
                    {
                        "isfinish": false,
                        "id": "81",
                        "name": "常见问题",
                        "pid": "Nogo",
                        "children": null
                    },
                    {
                        "isfinish": false,
                        "id": "82",
                        "name": "最新动态",
                        "pid": "Nogo",
                        "children": null
                    },
                    {
                        "isfinish": false,
                        "id": "83",
                        "name": "更多文件",
                        "pid": "Nogo",
                        "children": null
                    }
                ]
            },
            {
                "id": "Bus",
                "name": "合规驾考中心",
                "pid": null,
                "isfinish": true,
                "children": [
                    {
                        "id": "190",
                        "isfinish": false,
                        "name": "GSD驾照考核",
                        "pid": "Bus",
                        "children": null
                    },
                    {
                        "id": "191",
                        "isfinish": false,
                        "name": "换证中心",
                        "pid": "Bus",
                        "children": null
                    },
                    {
                        "id": "192",
                        "isfinish": false,
                        "name": "E证考核-会议",
                        "pid": "Bus",
                        "children": null
                    },
                    {
                        "id": "193",
                        "isfinish": false,
                        "name": "S证考核-讲者",
                        "pid": "Bus",
                        "children": null
                    }
                ]
            },
        ],
        "startdate": "2024-04-17",
        "weight": 1,
        "isactive": true,
        "createby": "luoc27",
        "createtime": "2024-04-17T16:28:13.000+08:00",
        "updateby": "luoc27",
        "updatetime": "2024-04-17T16:29:10.000+08:00"
    },
    {
        "searchValue": null,
        "createBy": null,
        "createTime": null,
        "updateBy": null,
        "updateTime": null,
        "remark": null,
        "params": {},
        "id": 50,
        "planname": "roy_test",
        "plandetailsjson": null,
        "plandetails": [
            {
                "id": "Bus",
                "name": "合规驾考中心",
                "pid": null,
                "isfinish": false,
                "children": [
                    {
                        "id": "190",
                        "isfinish": false,
                        "name": "GSD驾照考核",
                        "pid": "Bus",
                        "children": null
                    },
                    {
                        "id": "191",
                        "isfinish": false,
                        "name": "换证中心",
                        "pid": "Bus",
                        "children": null
                    },
                    {
                        "id": "192",
                        "isfinish": false,
                        "name": "E证考核-会议",
                        "pid": "Bus",
                        "children": null
                    },
                    {
                        "id": "193",
                        "isfinish": false,
                        "name": "S证考核-讲者",
                        "pid": "Bus",
                        "children": null
                    }
                ]
            },
            {
                "id": "Car",
                "name": "政策流程动态",
                "pid": null,
                "isfinish": false,
                "children": [
                    {
                        "id": "121",
                        "isfinish": false,
                        "name": "会议相关",
                        "pid": "Car",
                        "children": null
                    },
                    {
                        "id": "122",
                        "isfinish": false,
                        "name": "报销相关",
                        "pid": "Car",
                        "children": null
                    }
                ]
            },
            {
                "id": "Approval",
                "name": " \uD83C\uDF85材料审批",
                "pid": null,
                "isfinish": false,
                "children": [
                    {
                        "isfinish": false,
                        "id": "105",
                        "name": "非推广材料审批SOP",
                        "pid": "Approval",
                        "children": null
                    },
                    {
                        "isfinish": false,
                        "id": "106",
                        "name": "不良事件报告相关问题",
                        "pid": "Approval",
                        "children": null
                    },
                    {
                        "isfinish": false,
                        "id": "1333",
                        "name": "测试",
                        "pid": "Approval",
                        "children": null
                    },
                ]
            },
        ],
        "startdate": null,
        "weight": 1,
        "isactive": true,
        "createby": null,
        "createtime": null,
        "updateby": null,
        "updatetime": null
    }
    ]
}