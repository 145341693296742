const getters = {
  email: state => state.user.email,
  token: state => state.user.token,
  user: state => state.user.user,
  registerEmail: state => state.user.registerEmail,
  password: state => state.user.password,
  loading: state => state.app.loading,
  language: state => state.app.language,
  welcome: state => state.app.welcome,
  clear: state => state.app.clear,
  remember: state => state.app.remember,
  edit: state => state.app.edit,
  videoSrc: state => state.app.videoSrc,
  videoState: state => state.app.videoState
}

export default getters
