import moment from 'moment'
import Cookies from 'js-cookie'
import { siteCookieKey } from '@/utils/constants'
const { getEnvConfig } = require('@/settings')
const { appId, uidsBaseUrl, gigyaKey } = getEnvConfig()
const { emailKey, tokenKey, languageKey, } = siteCookieKey


Array.prototype.distinct = function () {
  return this.filter((itm, idx, self) => {
    return self.indexOf(itm) === idx
  })
}

Array.prototype.pushArray = function (arr) {
  const self = this
  if (Array.isArray(arr)) {
    arr.forEach((val) => {
      self.push(val)
    })
  }
}
export function loadGigyaScript(callback) {
  callback = typeof callback === 'function' ? callback : function () { };
  var head = document.getElementsByTagName('head')[0];
  var script = document.createElement('script');
  var code = `{
        enabledProviders: 'saml-RocheSSOUAT',
        disabledProviders: 'facebook,google,twitter,linkedin,amazon,yahoo',
        lang: 'zh-cn',
        include: 'profile,data,id_token'
      }`;
  script.type = 'text/javascript';
  script.src = `https://cdns.cn1.sapcdm.cn/js/gigya.js?apikey=${gigyaKey}`;
  //   try {
  //     script.appendChild(document.createTextNode(code));
  // } catch (ex) {
  //     script.text = code;
  // }
  script.onreadystatechange = function () {
    if (this.readyState == "loaded" || this.readyState == "complete") {
      callback();
    }
  }
  script.onload = callback;
  head.appendChild(script);
}

export function sessionHelper(sessionKey, parseJson) {
  const needParse = parseJson === undefined ? true : parseJson
  return {
    set: function (result) {
      window.sessionStorage.setItem(sessionKey, needParse && result ? JSON.stringify(result) : result)
    },
    get: function () {
      const data = window.sessionStorage.getItem(sessionKey)
      return needParse && data ? JSON.parse(data) : data
    },
    clear: function () {
      window.sessionStorage.removeItem(sessionKey)
    }
  }
}

export function ensureUpper(str) {
  return String(str || '').toUpperCase()
}

export function isNullOrEmptyString(val) {
  return typeof val === 'undefined' || val === null || (typeof val === 'string' && val.length === 0) || (val.toString().length === 0)
}

export function getTypeName(obj) {
  if (typeof obj === 'undefined' || obj === null) {
    return ''
  }

  const funcNameRegex = /function (.{1,})\(/
  const results = (funcNameRegex).exec(obj.constructor.toString())
  return (results && results.length > 1) ? results[1] : ''
}

// export function getUrlParameter(name) {
//   var url = document.location.toString()
//   // 如果url中有特殊字符则需要进行一下解码
//   url = decodeURI(url)
//   var arr1 = url.split('?')
//   var obj = {}
//   if (arr1.length > 1) {
//     var arr2 = arr1[1].split('&')
//     for (var i = 0; i < arr2.length; i++) {
//       var curArr = arr2[i].split('=')
//       obj[curArr[0]] = decodeURIComponent(curArr[1])
//     }
//   }

//   return obj[name]
// }

export function getUrlParameter(name) {
  name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
  var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
    results = regex.exec(location.href);
  return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
}
export function newGuid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1)
  }

  return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4()
}

String.prototype.formatDate = function (fmt) {
  return moment(new Date(this)).format(fmt)
}

Date.prototype.formatDate = function (fmt) {
  return moment(this).format(fmt)
}

moment.fn.formatDate = function (fmt) {
  return this.format(fmt)
}

Number.prototype.formatMoney = function (places, symbol, thousand, decimal) {
  places = !isNaN(places = Math.abs(places)) ? places : 2
  symbol = symbol !== undefined ? symbol : '$'
  thousand = thousand || ','
  decimal = decimal || '.'
  let number = this
  const negative = number < 0 ? '-' : ''
  const i = parseInt(number = Math.abs(+number || 0).toFixed(places), 10) + ''
  let j = (j = i.length) > 3 ? j % 3 : 0
  return symbol + negative + (j ? i.substr(0, j) + thousand : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousand) + (places ? decimal + Math.abs(number - i).toFixed(places).slice(2) : '')
}

Array.prototype.filterBy = function (field, value, skipNull) {
  if (!field) {
    throw "'field' cannot be not or empty"
  }
  if (typeof (field) !== 'string') {
    throw "'field' can only be String type"
  }

  // parse out valid nested fields
  var fields = field.split('.').filter(s => s)

  if (fields.length === 0 || fields[fields.length - 1].endsWith('[]')) {
    throw "'field' is invalid, should be like 'x' or 'x.y.z' or 'x.y[].z' format, and should not end with []."
  }

  // if null value, and skipNull=true, return original array without filtered.
  if (skipNull === true && !value) {
    return this
  }
  // clone it to protect original array from been modified while .map.
  var clone = JSON.parse(JSON.stringify(this))
  // because nested array filter with change array item itself, so here use '.map' but not '.filter'.
  return clone.map((item) => {
    var isMatch = false
    var obj = item
    for (let i = 0; i < fields.length; i++) {
      if (obj) {
        var f = fields[i]
        if (f.endsWith('[]')) {
          f = f.substr(0, f.length - 2)
          obj = obj[f]
          // is array as expected
          if (Array.isArray(obj)) {
            // pass spare-fields string to nested array
            var sparedFields = fields.filter((v, x) => {
              return x > i
            }).join('.')
            // record used fileds
            var usedFields = fields.filter((val, idx) => {
              return idx < i
            })

            var nestedArr = obj.filterBy(sparedFields, value, skipNull)
            // if nested array matches more than one item, then it matches.
            isMatch = nestedArr.length > 0
            if (isMatch) {
              // and current array field
              usedFields.push(f)

              var exp = 'item'
              usedFields.forEach((uf) => {
                exp += '[\'' + uf + '\']'
              })
              // eval 'item[x][y][z] = array' expression, yes, should update item itself.
              // eval(exp + '=nestedArr')
              // eval带来潜在安全性问题，修改
              var arr = item
              usedFields.forEach(function (field, idx) {
                if (idx === usedFields.length - 1) {
                  arr[field] = nestedArr
                } else {
                  arr = arr[field]
                }
              })
            }
            break
          }
          // is null, break! (because [] should only at non-ending field, so it cannot be null for further field access.)
          // or is other type value, break! not match.
          break
        } else {
          obj = obj[f]
        }
      } else {
        // item is null, break any further match test.
        if (i === 0) {
          break
        } else if (i === fields.length - 1) {
          // lastValue is null, match if skipNull or value is null as expected.
          isMatch = skipNull || value === obj
        } else {
          // not all nested fields queried, not match
          isMatch = false
        }
      }

      if (i === fields.length - 1) {
        isMatch = value === obj
      }
    }

    if (isMatch) {
      return item
    }
  }).filter(i => i)
}

export function compare(obj1, obj2) {
  if (typeof obj1 === 'string' && typeof obj2 === 'string') {
    if (obj1 === obj2) {
      return true
    }
    return false
  }
  if (typeof obj1 === 'object' && typeof obj2 === 'object') {
    var count1 = propertyLength(obj1)
    var count2 = propertyLength(obj2)
    if (count1 === count2) {
      for (const ob in obj1) {
        if (obj1.hasOwnProperty(ob) && obj2.hasOwnProperty(ob)) {
          if (obj1[ob] == null && obj2[ob] == null) {
            continue
          }

          if (obj1[ob] && obj1[ob].constructor === Array && obj2[ob] && obj2[ob].constructor === Array) {
            if (!compareArray(obj1[ob], obj2[ob])) {
              return false
            }
          } else if (typeof obj1[ob] === 'string' && typeof obj2[ob] === 'string') {
            if (obj1[ob] !== obj2[ob]) {
              return false
            }
          } else if (typeof obj1[ob] === 'object' && typeof obj2[ob] === 'object') {
            if (!compareJson(obj1[ob], obj2[ob])) {
              return false
            }
          } else {
            if (obj1[ob] !== obj2[ob]) {
              return false
            }
          }
        } else {
          return false
        }
      }
    } else {
      return false
    }
  }
  return true
}

export function compareJson(obj1, obj2) {
  if (obj1 === null && obj2 === null) return true
  if (obj1 === undefined && obj2 === undefined) return true
  if (JSON.stringify(obj1) === JSON.stringify(obj2)) {
    return true
  }
  return compare(obj1, obj2)
}

export function propertyLength(obj) {
  var count = 0
  if (obj && typeof obj === 'object') {
    for (const ooo in obj) {
      if (obj.hasOwnProperty(ooo)) {
        count++
      }
    }
    return count
  } else {
    return null
  }
}

export function compareArray(array1, array2) {
  if ((array1 && typeof array1 === 'object' && array1.constructor === Array) && (array2 && typeof array2 === 'object' && array2.constructor === Array)) {
    if (array1.length === array2.length) {
      for (var i = 0; i < array1.length; i++) {
        var ggg = compareJson(array1[i], array2[i])

        if (!ggg) {
          return false
        }
      }
    } else {
      return false
    }
  } else {
    return false
  }
  return true
}

export function validateEmail(email) {
  const regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return regex.test(email)
}

export function toJS(data) {
  return data && JSON.parse(JSON.stringify(data))
}

// 首先判断当前dom是否已经包含了要添加的类
export function hasClass(el, className) {
  const reg = new RegExp('(^|\\s)' + className + '(\\s|$)')
  return reg.test(el.className)
}
// 动态添加class
export function addClass(el, className) {
  if (hasClass(el, className)) {
    return
  }
  // 将原有的class按空格拆分，并将类名保存到newclass数组中
  var newClass = el.className.split(' ')
  // 将要添加的类也push到这个数组
  newClass.push(className)
  // 将数组拼接成字符串返回给dom
  el.className = newClass.join(' ')
}
// 动态删除class
export function removeClass(el, className) {
  if (!hasClass(el, className)) {
    return
  }
  // 将原有的class按空格拆分，并将类名保存到newclass数组中
  let newClass = el.className.split(' ')
  // 将要添加的类从这个数组删除
  newClass = newClass.filter(item => item !== className)
  // 将数组拼接成字符串返回给dom
  el.className = newClass.join(' ')
}

export function setLocationHref(href) {
  const url = location.href.slice(0, location.href.lastIndexOf('/'))
  return `${url}${href}`
}

export function parseStringToNumber(data) {
  return (isNullOrEmptyString(data) || isNaN(Number(data))) ? 0 : parseFloat(data)
}

export function formatValue(value) {
  if (isNullOrEmptyString(value) || isNaN(Number(value))) {
    return ''
  } else {
    if (value.toString().includes('.')) {
      const arr = value.toString().split('.')
      if (arr[0] === '') {
        return ''
      } else {
        return formatNumber(value)
      }
    } else {
      return Number(value)
    }
  }
}

export function formatNumber(value) {
  const regex = /(?:\.0*|(\.\d+?)0+)$/
  const str = parseFloat(value).toFixed(2).replace(regex, '$1')
  return str.includes('.') ? parseFloat(str).toFixed(2) : str
}

export function getUserShortName(data) {
  if (data === null || data === undefined) {
    return 'U'
  }
  const nameRegExp = /^([a-z]){1}.*?, ([a-z]){1}/i // Demo, Test
  const emailRegExp = /^([a-z]){1}.*?\.([a-z]){1}/i // test.demo@dxc.com
  let lastNameBegin, firstNameBegin
  if (nameRegExp.test(data)) {
    [, lastNameBegin, firstNameBegin] = data.match(nameRegExp)
  } else if (emailRegExp.test(data)) {
    [, firstNameBegin, lastNameBegin] = data.match(emailRegExp)
  } else {
    return data.slice(0, 1).toUpperCase()
  }
  return (firstNameBegin + lastNameBegin).toUpperCase()
}

export function isMobile() {
  if ((navigator.userAgent.match(/(iPhone|iPod|Android|ios|iOS|iPad|Backerry|WebOS|Symbian|Windows Phone|Phone)/i))) {
    return true
  }
  return false
}

export function isIOS() {
  if ((navigator.userAgent.match(/(iPhone|iPod|ios|iOS|iPad)/i))) {
    return true
  }
  return false
}

export function isAndroid() {
  if ((navigator.userAgent.match(/Android/i))) {
    return true
  }
  return false
}

export function isWeixin() {
  const ua = (window.navigator.userAgent || '').toLowerCase()
  return ua.includes('micromessenger') || ua.includes('_sq_')
}

export function isNarrowWindow() {
  return window.innerWidth <= 575
}

export function getEmail() {
  return Cookies.get(emailKey)
}
export function setEmail({ email, expires }) {
  if (expires) {
    Cookies.set(emailKey, email, { expires: new Date(expires) })
  } else {
    Cookies.set(emailKey, email)
  }
}
export function removeEmail() {
  Cookies.remove(emailKey)
}
export function getToken() {
  return Cookies.get(tokenKey)
}
export function setToken({ token, expires }) {
  if (expires) {
    Cookies.set(tokenKey, token, { expires: new Date(expires) })
  } else {
    Cookies.set(tokenKey, token)
  }
}
export function removeToken() {
  Cookies.remove(tokenKey)
}

export function getCookieLanguage() {
  return Cookies.get(languageKey)
}

export function setCookieLanguage(language) {
  const expires = getExpires()
  if (expires) {
    Cookies.set(languageKey, language, { expires: new Date(expires) })
  } else {
    Cookies.set(languageKey, language, { expires: new Date(moment().add(1, 'year').format()) })
  }
}

export function getExpires() {
  const token = getToken()
  const expires = getTokenExpiresTime(token)
  return expires && moment(expires * 1000).format()
}

export function getUrlKey(name) {
  return (
    decodeURIComponent(
      (new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
        location.href
      ) || [, ""])[1].replace(/\+/g, "%20")
    ) || null
  );
}

export function getLocalUrl() //获取当前url,过滤掉code参数
{
  var codestr = "code=" + getUrlKey("code");
  if (window.location.href.indexOf(`?${codestr}`) >= 0) {
    //匹配?code=xxxxx
    if (window.location.href.indexOf(`?${codestr}&`) >= 0)
      //匹配?code=xxxxx&
      return window.location.href.replace(`?${codestr}&`, "?");
    return window.location.href.replace(`?${codestr}`, ""); //匹配?code=xxxxx
  } //匹配?xxxxx&code=xxxxx
  else {
    return window.location.href.replace(`&${codestr}`, "");
  }
}
export function getCode() {
  var locationhref = encodeURIComponent(getLocalUrl());
  window.location.href =
    uidsBaseUrl +
    `/connect/oauth2/authorize?appId=${appId}&redirect_uri=${locationhref}`;

}
export function getTokenExpiresTime(token) {
  var expires = null
  if (token && typeof token === 'string') {
    const strings = token.split('.')
    if (strings.length > 1) {
      const info = JSON.parse(decodeURIComponent(escape(window.atob(strings[1].replace(/-/g, '+').replace(/_/g, '/')))))
      if (info && info.exp && typeof info.exp === 'number') {
        expires = info.exp
      }
    }
  }
  return expires
}

function throttle(fn, delay) {
  var t = null,
    begin = new Date().getTime();

  return function () {
    var _self = this,
      args = arguments,
      cur = new Date().getTime();
    clearTimeout(t);

    if (cur - begin >= delay) {
      fn.apply(_self, args);
      begin = cur;
    } else {
      t = setTimeout(function () {
        fn.apply(_self, args);
      }, delay);
    }
  }
}

/** JS 深度克隆
 * @param value 需要克隆的值
 */
export function deepClone(value) {
  // 排除原始类型的情况，函数时也满足此条件
  if (typeof value !== "object" || value === null) {
    return value;
  }
  // 克隆结果：1.数组 2.普通对象
  const result = Array.isArray(value) ? [] : {};
  // 设置克隆结果的原型链为 value 的原型链(即保持原型一致)
  Object.setPrototypeOf(result, Object.getPrototypeOf(value));
  // 浅层克隆
  for (const key in value) {
    // 排除原型上的属性
    if (value.hasOwnProperty(key)) {
      result[key] = deepClone(value[key]); // 针对这个对象的每一个属性值进行克隆，则达到深度克隆效果
    }
  }
  return result;
}

export function getPreifx(key) {
  let res = ""
  switch (key) {
    case "png":
      res = "data:image/png;base64,";
      break;
    case "jpg":
      res = "data:image/jpeg;base64,";
      break;
    case "jpeg":
      res = "data:image/jpeg;base64,";
      break;
    case "svg":
      res = "data:image/svg+xml;base64,";
      break;
    default:
      res = "data:image/jpeg;base64,";
      break;
  }
  return res
}

export {
  throttle
}
