<template>
  <div class="wrap">
    <div class="flex-container">
      <div ref="flexItem" v-for="item in toal" class="flex-item" :style="{ '--marginLeft': marginWidth + 'px' }">
        <a-tooltip>
          <template slot="title">
            {{ $t(trainList[item - 1].id + '.head') }}
          </template>
          <a-avatar v-if="trainList[item - 1].pos" @click="openHumanTrack" ref="flexItemAvatar"
            :style="{ '--size': size + 'vh', '--zindex': getZindex(item) }"
            :class="{ 'flex-item--selected': item == current }"
            :src="require(`../../assets/img/${trainList[item - 1].pos}.png`)" />
        </a-tooltip>
      </div>
    </div>
    <div class="progress-bar" ref="progressBar">
      <div class="progress"></div>
      <div class="inner" :style="{ '--left': progress + '%' }"></div>
      <div class="runner" v-if="imageUrl" :style="{ '--left': progress + '%' }">
        <img class="runnerImg" :src="imageUrl" alt="图片">
      </div>
    </div>
  </div>
</template>
   
<script>

export default {
  name: 'index',
  props: {
    //   progress: {
    //     type: [Number,String],
    //     default: '0'
    //   },
    toal: {
      type: [Number, String],
      default: '0'
    },
    current: {
      type: [Number, String],
      default: '0'
    },
    imageUrl: {
      type: String,
      default: './../assets/img/b-car.png'
    },
    trainList: {
      type: Array,
      default: []
    }
  },
  computed: {
    progress() {
      return (this.current / this.toal) * 100
    },
    size() {
      return 4
    }
  },
  watch: {
    trainList: {
      handler(val) {
        // console.log("val",this.imageUrl)
        // 当 data 属性发生变化时，检查 this.$refs.flexItemAvatar 是否存在  
        this.$nextTick(() => {
          if (this.$refs.flexItemAvatar) {
            // 现在可以安全地访问 this.$refs.flexItemAvatar  
            // console.log("trainList", this.trainList)
            let flexItemAvatarWidth = this.$refs.flexItemAvatar[0].$el.clientWidth;
            let progressBarWidth = this.$refs.progressBar.clientWidth;
            this.marginWidth = (progressBarWidth / this.toal) - (flexItemAvatarWidth / 2)
            // console.log("marginWidth", this.marginWidth)
          }
        });
      },
      immediate: true // 立即执行一次 handler，即使 data 在初始化时就是可用的  
    }
  },
  mounted() {

  },
  methods: {
    getZindex(item) {
      if (item == this.current) {
        return 100
      } else {
        return 100 - item
      }
    },
    openHumanTrack() {
      this.$emit("openHumanTrack")
    }
  },
  data() {
    return {
      marginWidth: 0,
      dongtai: require('../../assets/img/test.gif'),
      dongtai2: require('../../assets/img/b-car.png')
    }
  }
}
</script>
   
<style lang="scss" scoped>
.wrap {
  position: relative;
  height: 10vh;
}

.flex-container {
  position: absolute;
  top: -6vh;
  width: 100%;
  display: flex;

  .flex-item {
    flex: 1;
    margin-left: var(--marginLeft);

    .flex-item--selected {
      transform: scale(1.5);
    }

    .ant-avatar-image {
      width: var(--size);
      height: var(--size);
      position: absolute;
      z-index: var(--zindex);
      background-color: #ddb166;
      border: solid white 1px;
      box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.3);
      padding: 0.5vh;
    }
  }
}

.progress-bar {
  position: absolute;
  top: 2vh;
  width: 100%;
  height: 4vh;
  border-radius: 9px;
  background-color: rgb(255, 237, 212);
  border: 3px solid rgba(182, 108, 21, .95);
  box-shadow: 0px 2px 3px 2px rgba(182, 108, 21, 0.6);

  .inner {
    position: absolute;
    top: 0;
    z-index: 10;
    border-radius: 6px;
    height: 100%;
    background: #f28e01;
    transition: left 1s ease-in-out;
    animation: changeWidth 1s forwards linear, shrink 1s linear infinite;
    background-image: url('../../assets/img/bc.png');
    background-size: cover;
  }
}

.progress {
  width: 0%;
  height: 100%;
  background-color: #00aaff;
  transition: width 1s ease-in-out;

}

.runner {
  width: 7vh;
  height: 7vh;
  background-image: var(--backgroundImage);
  background-size: cover;
  position: absolute;
  top: -10px;
  transition: left 1s ease-in-out;
  -webkit-animation: run 1s forwards linear;
  animation: run 1s forwards linear;
  margin-left: -3vh;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.runnerImg {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}

@keyframes run {
  0% {
    left: 0;
  }

  100% {
    left: var(--left);
  }
}

@keyframes changeWidth {
  0% {
    width: 0;
  }

  100% {
    width: var(--left);
    background-position: 10vh 0
  }
}

@keyframes shrink {
  0% {
    background-position: 0 0
  }

  100% {
    background-position: 10vh 0
  }
}
</style>
<style>
@media screen and (max-width: 560px) {
  .flexRow .flexItems .picItem {
    width: 5vh !important;
  }

  .flexRow .flexItems .picItem img{
    padding: 0.5vh !important;
  }

  .flexRow .flexItems .picItem .pic {
    width: 5vh !important;
    height: 5vh !important;
  }
}
</style>