<template>
    <!-- 弹出框 -->
    <div id="successCompleteDiag" class="b-modal">
        <div class="modal-inner">
            <h2 class="m-header" data-i18n="Opinion.head">
                {{ $t('Dialog.head') }}
            </h2>
            <div class="m-body">
                <!-- <div id="lottie_box" class="animation" /> -->
                <Lottie :options="defaultOptions" class="animation" :height="120"/>
                <div class="m-title">{{ $t('Training.completeTitle') }} <span class="trainName"> {{ trainName }}</span> </div>
                <p style="text-align: center;" class="btnDisplay">
                    <button @click="completeTrain" data-fancybox-close
                        style="width:100px;height:30px;line-height:30px;background:#5d3a10;color:#fff;border-radius: 10px;border:1px solid #5d3a10;"
                        data-i18n="Dialog.button"> {{ $t('Dialog.button') }} </button>
                </p>
            </div>
        </div>
    </div>
</template>

<script>

import Lottie from 'vue-lottie/src/lottie.vue'
import * as anData from '@/assets/lottie/success.json'

export default ({
    name: "successCompleteDiag",
    data() {
        return {
            defaultOptions: {
                animationData: anData.default
            }

        }
    },
    components: {
        Lottie
    },
    props: {
        trainName: {
            type: String,
            default: 'XXX培训'
        },
    },
    computed: {
    },
    created() {
        let that = this;
    },
    mounted() {
        // this.anim = lottie.loadAnimation({
        //     container: document.getElementById("lottie_box"),
        //     renderer: 'svg',
        //     loop: true,
        //     autoplay: true,
        //     animationData: anData.default,
        // });
    },
    methods: {
        completeTrain() {
            this.$emit("completeTrain")
        }
    }
})
</script>

<style scoped>
.animation{
    height: 40vh;
    width: auto;
}
.m-body {
    height: 48vh;
    padding-top: 3vh;
}

.m-title{
    text-align: center;
}

.trainName {
    text-align: center;
    font-weight: bold;
    position: relative;
    color:#fff;
    /***
    text-transform:uppercase;
    text-shadow:
    0px 1px 0px #c0c0c0,
    0px 2px 0px #b0b0b0,
    0px 3px 0px #a0a0a0,
    0px 4px 0px #909090,
    0px 5px 10px rgba(0, 0, 0, 0.6);
    **/
}

.btnDisplay {
    margin-top: 1vh;
    position: absolute;
    bottom: 2vh;
    left: 50%;
    transform: translateX(-50%) !important;
}
</style>
<style>
@media screen and (max-width: 560px) {
   #successCompleteDiag .animation{
        height: 20vh;
    }
    #successCompleteDiag .m-body {
        height: 32vh;
    }
}
</style>
