<template>
  <div v-if="category" class="content bg-green shadow">
    {{ $t(category.headers) }}
    <span />
  </div>
</template>

<style scoped>
 .main{
    display: flex;
    max-width: calc(100% - 5.2vh);
    margin: 0 0.8vh;
    align-items: center;
  }
  .main .content:hover {
    z-index: initial;
    transform: translateY(-1px);
    box-shadow: 2px 5px 5px 3px rgba(0, 0,3,.2);
  }
  .main .content {
  padding: 1.8vh 2.1vh 2.1vh 1.8vh;
  border-radius: 0.12vh;
  display: inline-flex;
   max-width: calc(200%);
  align-items: center;
  font: italic 500 2.4vh 'Microsoft yahei';
  position: relative;
  min-height: 1.36vh;
  line-height: 0.7vh;
  text-align: center;
  white-space: nowrap;
  border-radius: 0.9vh 0.6vh 0.64vh 0;

}
.main .content::after {
  content: "";
  top: 3.75vh;
  position: absolute;
  z-index: 10;
  display: inline-block;
  width: 1.48vh;
  height: 1.48vh;
  left: calc(50% - 1.32vh);
  right: initial;
  background-color: inherit;
  transform: rotate(-45deg) skew(0,12deg);
}
.bg-green {
  background-color: #76654f;
  color: #fcfcfb;
}
.main .content::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  background-color: transparent;
  border-color: transparent #76654f transparent transparent!important;
  border-style: solid;
  border-width: 4.3vh 1.4vh 0 0;
  left: -1.25vh;
  top: 0.3vh;
  transform: rotate(0deg);
  opacity: 1;
  border-bottom-left-radius: 2.8vh;
}
.main .content span {
  width: calc(100% - 3px);
  display: inline-block;
  height: 1vh;
  position: absolute;
  top: -0.7vh;
  right: 0;
  overflow: hidden;
  border-top-left-radius: 0.5vh!important;
}
.main .content span::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  background-color: transparent;
  border-color: transparent #76654f transparent transparent!important;
  border-style: solid;
  border-width: 0.7vh 13vw 0 0;
  right: 0.3vh;
  bottom: 0;
  border-top-right-radius: 2.8vh;
}
</style>

<script>

export default {
  name:"catalogTitle",
  props: {
    category: {
      type: Object,
      default: () => { return {} }
    }
  }
}
</script>

