import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { getCookieLanguage } from '@/utils/util'
import { enLocale,zhLocale } from './myLocale'

Vue.use(VueI18n) // 通过插件的形式挂载，通过全局方法 

//定义语言包
const messages = {
  en: {
    ...enLocale
  },
  zh: {
    ...zhLocale
  }
}

//获取当前环境下的语言
export function getLanguage() {
  const lang = getCookieLanguage()
  if (lang) {
    return lang
  }
  // console.log("getLanguage");
  const language = (navigator.language || navigator.browserLanguage).toLowerCase()
  const locales = Object.keys(messages)
  for (const locale of locales) {
    if (language.indexOf(locale) > -1) {
      return locale
    }
  }
  return 'en'
}

const i18n = new VueI18n({
  locale: getLanguage(), // 语言标识 //this.$i18n.locale // 通过切换locale的值来实现语言切换
  messages,
  silentTranslationWarn: true,
})


export default i18n
