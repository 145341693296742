<template>
  <div id="drivingLicense" class="b-modal changeWidth">
    <span class="left" />
    <span class="right" />
    <a href="javascript:;" data-fancybox-close class="m-close">
      <span class="fancybox-close-text" title="关闭">{{ $t('Fancbox.close') }}</span>
    </a>
    <div class="m-body">
      <div class="user">
        <span class="myname">{{ nickname }}</span>
        <span class="email">{{ email }}</span>
      </div>
      <div class="quasiDriving">
        <ul v-if="imgResult.length > 0">
          <li v-for="item in imgResult" :key="item.code">
            <img v-if="item.imageUrl" @click="openClick(item.url, item.isvpn)" :src="item.imageUrl" alt="" />
          </li>
        </ul>
        <ul v-else class="textUl">
          <span class="textPic" data-i18n="TextPic.head">{{ $t('TextPic.head') }}</span>
        </ul>
      </div>
      <div class="tips">{{ $t("PersonalGlory.tips") }}</div>
    </div>
  </div>
</template>

<script>
import { getDrivingLicense, getDownloadBase64 } from '@/api'
import { getPreifx, isNullOrEmptyString } from '@/utils/util'

export default {
  name: "drivinglicense",
  data() {
    return {
      ad: '',
      email: '',
      imgList: [],
      imgResult: [],
      base64UrlArray: {},
      nickname: "",
      loading: true
    }
  },
  computed: {

  },
  created() {

  },
  mounted() {
    const that = this
    getDrivingLicense().then((res) => {
      // console.log('res.data:', res.data);
      that.nickname = res.nickname
      that.email = res.email
      let pass = res.pass
      let nopass = res.nopass
      let passItms = that.hanleCode(pass, 'sealbase64')
      let nopassItms = that.hanleCode(nopass, 'nopssbase64')
      // console.log("that.imgResult", that.imgResult)
      that.imgResult = passItms.concat(nopassItms)
      // console.log("that.imgResult", that.imgResult)
      // for (let i = 0; i < res.data.length; i++) {
      //   that.imgList.push(res.data[i].code)
      // }
      // getDrivingLicenseImg().then((res) => {
      //   // console.log('res.data.imgdata', res.data);
      //   const imgData = res.data
      //   this.imgResult = imgData.filter((item) => {
      //     if (this.imgList.includes(item.code)) {
      //       return item
      //     }
      //   })
      //   // console.log("this.imgResult",this.imgResult);
      //   this.imgResult.map( item => {
      //       that.chargeBase64(item.sealbase64,function(data){
      //         that.$set(that.base64UrlArray,item.code,data)
      //       })         
      //     })
      // })
    })
  },
  methods: {
    filterBase64(codeImages) {
      return codeImages.replace(/[\r\n]/g, "");
    },
    ensureCorrectUrlProtocol(url) {
      // 使用正则表达式检查URL是否以http://或https://开头  
      const protocolRegex = /^https?:\/\//;

      // 如果URL没有以http://或https://开头，则添加http://  
      if (!protocolRegex.test(url)) {
        // 你可以根据条件添加http或https，这里我默认添加http  
        // 如果需要添加https，可以修改这里的'http://'为'https://'  
        return 'http://' + url;
      }

      // 如果URL已经包含协议前缀，则直接返回原URL  
      return url;
    },
    openClick(url, isvpn) {
      let that = this
      if (isvpn) {
        this.$nextTick(() => {
          that.$confirm({
            title: that.$i18n.tc('LinksFor.title'),
            content: that.$i18n.tc('LinksFor.message'),
            okText: that.$i18n.tc('LinksFor.okText'),
            cancelText: that.$i18n.tc('LinksFor.cancelText'),
            onOk() {
              if (!isNullOrEmptyString(url)) {
                let resUrl = that.ensureCorrectUrlProtocol(url)
                if (!window.open(resUrl)) { // popup blocked, offer direct download:
                    if (confirm("Displaying New Document\n\nUse Save As... to download, then click back to return to this page.")) { location.href = resUrl; }
                  }
              }else{
                return false
              }
            },
            onCancel() { },
            zIndex: 99999
          });
        })
      } else {
        if (!isNullOrEmptyString(url)) {
          let resUrl = that.ensureCorrectUrlProtocol(url)
          window.open(resUrl, "_blank")
        }else{
          return false
        }
      }

    },
    hanleCode(data, p) {
      let that = this
      data.map(async (item) => {
        let url = await that.chargeBase64(item[p])
        that.$set(item, 'imageUrl', that.filterBase64(url))
        // return item.imageUrl = that.filterBase64(url)
      })
      return data
    },
    chargeBase64(key) {
      let data = ""
      return new Promise(resove => {
        getDownloadBase64(key).then(res => {
          if (res && res.name && res.name.includes(".")) {
            let h = res.name.substring(res.name.lastIndexOf('.') + 1)
            let prefix = getPreifx(h);
            data = prefix + res.base64;
            resove(data);
          } else {
            resove("");
          }
        })
      })
    }
  }
}
</script>
<style scoped>
.changeWidth {
  position: relative;
  min-width: 80vw;
  min-height: 80vh;
}

.changeWidth .left,
.changeWidth .right {
  position: absolute;
  top: 0;
  height: 100%;
  width: 10px;
  background-color: #CB9A4E;
}

.changeWidth .right {
  left: 0;
}

.changeWidth .left {
  right: 0;
}

.b-modal::before,
.b-modal::after {
  content: '';
  position: absolute;
  left: 0;
  width: 100%;
}

.b-modal {
  width: 100vw;
  height: 70vh;
  background: url(../../assets/img/license.jpg) no-repeat;
  background-size: 100% 100%;
  color: black;
  font: italic 500 0.5em 'Microsoft yahei';
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  /* 针对WebKit引擎 */
  image-rendering: crisp-edges;
  /* WebKit引擎现不支持该值，Firefox支持 */
  -ms-interpolation-mode: nearest-neighbor;
}

.m-body {
  width: 100%;
}

.quasiDriving {
  position: absolute;
  width: 39.5%;
  height: 37.5%;
  top: 30%;
  left: 50%;
  /*overflow: hidden;*/
  /*overflow-x: hidden;*/
  overflow-y: auto;
  /* background-color: aquamarine; */
}

/*.quasiDriving::-webkit-scrollbar {
    display:none
}*/
.myname {
  position: absolute;
  top: 35.5%;
  left: 19%;
  width: 23%;
  height: 5%;
  display: flex;
  align-items: center;
  overflow: hidden;
  overflow-x: auto;
  overflow-y: auto;
  font-size: 2.3vh;
}

.email {
  position: absolute;
  top: 49%;
  left: 19%;
  width: 23%;
  height: 8%;
  display: flex;
  align-items: center;
  overflow: hidden;
  overflow-x: auto;
  overflow-y: auto;
  font-size: 2.3vh;
  line-height: 2.3vh;
  /*文字换行  汉字和英文数字字符也适配*/
  white-space: normal;
  word-break: break-all;
  word-wrap: break-word;
}

.email::-webkit-scrollbar {
  display: none;
}

.quasiDriving ul {
  /*overflow-y: scroll;*/
  margin: 0;
  padding: 0 0 0 5vh;
}

.quasiDriving li {
  list-style: none;
  text-align: center;
  float: left;
  width: 16.5vh;
  height: 16.5vh;
  margin-right: 3vh;
  margin-top: 3.5vh;
  /* background-color: pink;  */
  border-radius: 50%;
  position: relative;
}

.quasiDriving li img {
  width: 100%;
  height: 100%;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  /* 针对WebKit引擎 */
  image-rendering: crisp-edges;
  /* WebKit引擎现不支持该值，Firefox支持 */
  -ms-interpolation-mode: nearest-neighbor;
  cursor: pointer;
}

.tips {
  position: absolute;
  bottom: 25.5%;
  left: 51%;
  width: 40%;
  height: 6%;
  display: flex;
  align-items: center;
  font-size: 0.7rem;
}

.m-close {
  padding: 5px;
  top: 11vh;
  right: 4%;
}

.textPic {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0 30px;
  transform: translateY(-4%);
}

.quasiDriving .textUl {
  padding: 0;
  height: 100%;
}

.m-close .iconfont {
  font-size: 3vh;
}

.myTag2 {
  position: absolute;
  left: 5vh;
  bottom: -5vh;
  transform: translateY(-50%);
  opacity: 0.8;
}
</style>