const zhLocale =  {
  "Opinion":{
    "head":"意见反馈",
    "submit":"提交"
  },
  "More":{
    "head":"ROBOT",
    "submit":"提交"
  },
  "Catalog":{

    "head":"目录",

    "link1":"常用工具",

    "link2":"新员工",

    "link3":"办会",

    "link4":"出差",

    "link5":"政策窗口",

    "link6":"第三方",

    "link7":"合规项目",
    "Other":"Other"

  },
  "BulletinBoard":{
    "head":"更新公告"
  },
  "PersonalGlory":{
    "head":"我的合规驾照"
  },

  "ZHEN":{
    "head":"English"
  },
  "Bonus":{
    "head":"合规奖金"
  },
  "SearchBody":{
    "head":"的搜索内容"
  },
  "Information":{
    "head":"我的合规信息"
  },
   "LinksFor":{
    "title" : "请问您是否已链接VPN？",
    "message":"请链接VPN获取对应文件，如已链接，请点击确定打开资源文件。",
    "okText": "确定",
    "cancelText": "取消"
  },
  "SearchBtn":{
    "head":"搜索",
    "placeholder":"请输入关键词"
  },
  "TextPic":{
    "head":"您暂无项目资质，请勿无证驾驶。"
  },
  "Fancbox":{
    "close":"关闭"
  },
  "Dialog":{
    "head":"完成培训",
    "button":"我知道了",
    "tips":"您的培训路线:"
  },
  "Training":{
    "head":"您被分配了",
    "button":"我知道了",
    "waitingTraining":"待培训",
    "completeTraining":"已完成培训",
    "completeTitle":"恭喜您完成"
  },
  "HumanImg":{
    "head":" 进度",
    "goTonext":"点击下一站"
  },
  "Sign":{
    "title1":"第",
    "title2":"站",
    "tips":"请完成当前培训，再前往下一站",
    "tipsTitle":"请点击 "
  },
  "PersonalGlory":{
    "head":"我的合规驾照",
    "tips":"可点击证照图片前往对应培训地址"
  }
}

const enLocale = {
  "Opinion":{
    "head":"Feedback",
    "submit":"Submit"
  },
  "More":{
    "head":"ROBOT",
    "submit":"Submit"
  },
   "Catalog":{

    "head":"Catalog",

    "link1":"CommonlyUT",

    "link2":"NewEmployee",

    "link3":"OfficeWill",

    "link4":"BusinessTrip",

    "link5":"PolicyWindow",

    "link6":"ThirdParty",

    "link7":"ComplianceProgram",  
    "Other":"Other"

  },
  "BulletinBoard":{
    "head":"Announcement"
  },
   "PersonalGlory":{
    "head":"Compliance License"
  },

  "ZHEN":{
    "head":"中文"
  },

  "Bonus":{
    "head":"Compliance Bonus"
  },
  
  "SearchBody":{
    "head":"Search content of"
  },
  "Information":{
    "head":"My Information"
  },
  "LinksFor":{
    "title":"Are you connected to a VPN?",
    "message":"Please link the VPN to obtain the corresponding file. If it has been linked, please click Confirm to open the resource file. ",
    "okText": "Confirm",
    "cancelText": "Cancel"
  },
  "SearchBtn":{
    "head":"Search",
    "placeholder":"Please input keyword"
  },
  "TextPic":{
    "head":"You are not qualified for the project, please do not drive without a license."
  },
  "Fancbox":{
    "close":"Close"
  },
  "Dialog":{
    "head":"Complete Training",
    "button":"Confirm",
    "tips":"Your Training Route:"
  },
  "Training":{
    "head":"You've got a new training task:",
    "button":"Confirm",
    "waitingTraining":"To be trained",
    "completeTraining":"Training completed",
    "completeTitle":"Congratulations on completing"
  },
  "HumanImg":{
    "head":" progress",
    "goTonext":"Go to next station"
  },
  "Sign":{
    "title1":"Station ",
    "title2":" ",
    "tips":"Please complete the current training before proceeding to the next stop",
    "tipsTitle":"A new training task: "
  },
  "PersonalGlory":{
    "head":"Compliance License",
    "tips":"You can click on the license image to go to the corresponding training address"
  },
}

export {
  zhLocale,
  enLocale
}
