import Vue from 'vue'
import App from './App.vue'
import $ from './plugins/jquery.vendor.js'
import './plugins/plugins.js'
import router from './router'
import i18n from './locale'
import store from './store'
import axios from 'axios'  // 安装axios后引入
import moment from 'moment'
import '@/permission' // permission control
import {Button,Tree , Input, Icon , Alert,  Dropdown , Menu, Collapse, Result, Modal, Message, Spin, Badge, Tooltip, Popover, Tag, Progress, Steps, Avatar, Card, Row, Col} from 'ant-design-vue';

import VueDirectiveImagePreviewer from 'vue-directive-image-previewer'
import 'vue-directive-image-previewer/dist/assets/style.css'

Vue.use(VueDirectiveImagePreviewer, {
  //1. wrapper背景颜色———— 你也可以写成background: '#000'  //支持rgba、rgb和image: 'url(xxx)'
  background: {    
    color: '#000' 
  },
  
  //2. 转换动画
  animate: {
    duration: 200,
    delay: 100
  },

  //3. 鼠标样式(css)
  cursor: 'pointer',
  zIndex:99999,
}) 
Vue.use(Button)
Vue.use(Tree)
Vue.use(Input)
Vue.use(Icon)
Vue.use(Alert)
Vue.use(Dropdown)
Vue.use(Menu)
Vue.use(Collapse)
Vue.use(Result)
Vue.use(Modal)
Vue.use(Message)
Vue.use(Spin)
Vue.use(Badge)
Vue.use(Tooltip)
Vue.use(Popover)
Vue.use(Tag)
Vue.use(Progress)
Vue.use(Steps)
Vue.use(Avatar)
Vue.use(Card)
Vue.use(Col)
Vue.use(Row)

// 懒加载base64图片的指令
Vue.directive('base64-img', {
  bind(el, binding) {
    const img = new Image();
    img.src = binding.value; // base64字符串
    img.style = 'opacity: 0;';
 
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        el.appendChild(img);
        observer.unobserve(el);
      }
    });
 
    observer.observe(el);
  }
});

Vue.prototype.$axios = axios  // 将axios挂载到原型上方便使用
Vue.prototype.$confirm = Modal.confirm
Vue.prototype.$moment = moment
Vue.prototype.$message = Message

Vue.config.productionTip = false

new Vue({
  i18n,
  router,
  store,
  render: h => h(App),
}).$mount('#app')
