<template>
  <div id="box" ref="box" v-show="flag">
    <div class="marquee-box" ref="marquee" @mouseover="menter" @mouseleave="mleave">
      <p ref="cmdlist" id="pWidth">
        为了您更好的浏览体验，请切换至横屏界面显示
      </p>
    </div>
    <!-- <span @click="changeFlag" class="cloneS">X</span> -->
    <span @click="changeFlag" class="cloneS fancybox-close-text" title="关闭">{{$t('Fancbox.close')}}</span>
  </div>
</template>


<script>
export default {
  name: 'HelloWorld',
  data () {
    return {
      flag :true,
      value: 0,
      timer: '',//计时器
      pwidth:0,//公告文本的宽度
      windowWidth:document.documentElement.clientWidth,// 设备屏幕的宽度
      }
    },

  mounted() {
    let element = this.$refs.cmdlist;
    this.pwidth = document.defaultView.getComputedStyle(element,'').width.split('px');
    this.timer = setInterval(this.clickCommend, 6);
  },
  watch:{
    value(newValue, oldValue) {
      let allWidth= parseInt(this.windowWidth)+parseInt(this.pwidth[0]);
      if(newValue <= -allWidth){
        this.$refs.cmdlist.style.marginLeft = this.windowWidth+"px";
        this.value = 0;
      }
    },
  },
  methods:{
    clickCommend(e) {
      let _this = this;
      this.$nextTick(() => {
        this.value -=1;
        this.$refs.cmdlist.style.marginLeft = _this.windowWidth+this.value+"px";
      });
    },
    menter(){
      clearInterval(this.timer);
    },
    mleave(){
      this.timer = setInterval(this.clickCommend, 10);
    },
    changeFlag(){
      this.flag = !this.flag;
    }
  },
  beforeDestroy() {
    clearInterval(this.timer);
  }
}

</script>

<style scoped>
#box {
  width: 100%;
  height: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 111111111;
}
#box::-webkit-scrollbar{
  display:none
}
.marquee-box  {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow:auto;
  background-color:rgb(70, 69, 69);
  opacity: 0.9;
}
#pWidth{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 300%;
  height: 100px;
  padding: 0;
  margin: 0;
  line-height: 100px;
  font-size: 3.5rem;
  background-image: -webkit-linear-gradient(left,#e9a5a5,#b8c1c0 10%, #65c0e0 20%, #aea2db 30%, #81c1d9 40%, #e9a5a5 50%, #b8c1c0 60%, #65c0e0 70%, #aea2db 80%, #81c1d9 90%, #e9a5a5);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  background-size: 200% 100%;
  animation: a 5s infinite linear;
  display: block;
  word-break: keep-all;
  white-space: nowrap;
  overflow:hidden;
  font: 'Microsoft yahei';
  
}
@keyframes a {
  0% {
    background-position:  0 0;
  }

  100% {
    background-position: -100% 0;
  }
}
::-webkit-scrollbar {
  width: 0 !important;
}
::-webkit-scrollbar {
  width: 0 !important;height: 0;
}
.cloneS{
  position: absolute;
  right: 1rem;
  line-height: 50px;
  font-size: 20px;
  color: aliceblue;
}

</style>
