const loginPath = '/user/login'
const registerPath = '/user/register'
const noRedirectPaths = [
  '/404',
  '/403',
  '/500',
  loginPath
]

const prefix = 'roche-map-'
const siteCookieKey = {
  emailKey: `${prefix}Email`,
  tokenKey: `${prefix}Token`,
  languageKey: `${prefix}Language`,
}

const animationMapping =  [
  {
    name: 'GSD',
    tips: "请点击赞助和捐助",
    anchor: ".b-GSD",
    len: 210,
    dur: 350,
    pos: ".b-GSD",
    nickName: "赞助和捐助"
  },
  {
    name: 'HCP',
    tips: "请点击日常交往",
    anchor: ".b-HCP",
    len: 250,
    dur: 420,
    pos: ".b-GSD",
    nickName: "日常交往"
  },
  {
    name: 'GOV',
    tips: "请点击合规奖金",
    anchor: ".b-gov",
    len: 380,
    dur: 630,
    pos: ".b-GSD",
    nickName: "合规奖金"
  },
  {
    name: "SSC",
    tips: "请点击SSC/Concur报销",
    anchor: ".b-concur",
    len: 450,
    dur: 750,
    pos: ".b-GSD",
    nickName: "SSC/Concur报销"
  },
  {
    name: 'Train',
    tips: "请点击差旅出行",
    anchor: ".b-train",
    len: 700,
    dur: 850,
    pos: ".b-GSD",
    nickName: "差旅出行"
  },
  {
    name: 'Speaker',
    tips: "请点击讲者",
    anchor: ".b-speaker",
    len: 800,
    dur: 1050,
    pos: ".b-GSD",
    nickName: "讲者"
  },
  {
    name: 'Spot',
    tips: "请点击飞鹰护航",
    anchor: ".b-spotCheck",
    len: 900,
    dur: 1250,
    pos: ".b-GSD",
    nickName: "飞鹰护航"
  },
  {
    name: 'Nogo',
    tips: "请点击会议酒店（高风险商户）",
    anchor: ".b-travel",
    len: 1200,
    dur: 1300,
    pos: ".b-GSD",
    nickName: "会议酒店（高风险商户）"
  },
  {
    name: 'Contact',
    tips: "请点击常用联系方式",
    anchor: ".b-BPs",
    len: 1450,
    dur: 1450,
    pos: ".b-speaker",
    nickName: "常用常用联系方式"
  },
  {
    name: 'Restaurants',
    tips: "请点击实名认证/知情同意",
    anchor: ".b-restaurant",
    len: 1500,
    dur: 1550,
    pos: ".b-speaker",
    nickName: "实名认证/知情同意"
  },
  {
    name: 'Bus',
    tips: "请点击合规驾考中心",
    anchor: ".b-bus",
    len: 1600,
    dur: 1650,
    pos: ".b-travel",
    nickName: "合规驾考中心"
  },
  {
    name: 'Flight',
    tips: "请点击法务合规",
    anchor: ".b-aircraft",
    len: 1900,
    dur: 1850,
    pos: ".b-travel",
    nickName: "法务合规"
  },
  {
    name: 'Roche',
    tips: "请点击罗氏新手关爱村",
    anchor: ".b-roche",
    len: 1950,
    dur: 1950,
    pos: ".b-travel",
    nickName: "罗氏新手关爱村"
  },
  {
    name: 'OtherOther',
    tips: "请点击患者",
    anchor: ".b-other",
    len: 2000,
    dur: 1950,
    pos: ".b-travel",
    nickName: "患者"
  },
  {
    name: 'PACE',
    tips: "请点击合规先锋",
    anchor: ".b-champion",
    len: 2100,
    dur: 2100,
    pos: ".b-travel",
    nickName: "合规先锋"
  },
  {
    name: 'Hospital',
    tips: "请点击活动与会议",
    anchor: ".b-hospital",
    len: 2450,
    dur: 2350,
    pos: ".b-restaurant",
    nickName: "活动与会议"
  },
  {
    name: 'Club',
    tips: "请点击常用软件及系统",
    anchor: ".b-club",
    len: 2500,
    dur: 2450,
    pos: ".b-restaurant",
    nickName: "常用软件及系统"
  },
  {
    name: 'Hiring',
    tips: "请点击经理合规领导力",
    anchor: ".b-hiring",
    len: 2550,
    dur: 2550,
    pos: ".b-other",
    count: 4,
    nickName: "经理合规领导力"
  },
  {
    name: 'Car',
    tips: "请点击数字沟通平台",
    anchor: ".b-car",
    len: 2560,
    dur: 2560,
    pos: ".b-other",
    nickName: "数字沟通平台"
  },
  {
    name: 'Cinema',
    tips: "请点击合规电影院",
    anchor: ".b-cinema",
    len: 2850,
    dur: 2850,
    pos: ".b-other",
    nickName: "合规电影院"
  },
  {
    name: 'Puzzle',
    tips: "请点击合规拼图/培训",
    anchor: ".b-puzzle",
    len: 3050,
    dur: 3050,
    pos: ".b-puzzle",
    nickName: "合规拼图/培训"
  },
  {
    name: 'Meetings',
    tips: "请点击采购/供应商及流程",
    anchor: ".b-meetings",
    len: 3500,
    dur: 3500,
    pos: ".b-puzzle",
    nickName: "采购/供应商及流程"
  },
  {
    name: 'Approval',
    tips: "请点击材料审批",
    anchor: ".b-approval",
    len: 3600,
    dur: 3600,
    pos: ".b-puzzle",
    nickName: "材料审批"
  },

  {
    name: 'DP',
    tips: "请点击经销商和药店",
    anchor: ".b-drugstore",
    len: 3650,
    dur: 3650,
    pos: ".b-puzzle",
    nickName: "经销商和药店"
  }
]
export {
  loginPath,
  registerPath,
  noRedirectPaths,
  siteCookieKey,
  animationMapping
}
