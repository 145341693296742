<template>
    <g id="human" @click="handleClick">
    <g v-if="type===1">
    <rect x="-2.42" y="-73.07" fill="#E68150" width="4.83" height="4.36"/>
    <path fill="#2F1715" d="M-2.55-0.18C-2.6-0.11-2.69-0.05-2.84,0C-4.03-0.03-5.21-0.07-6.4-0.1c-0.48,0.03-0.89-0.22-1.01-0.59
      c-0.09-0.28,0.03-0.57,0.08-0.68c0.18-0.4,0.35-1.07,0.35-2.25c1.26,0.03,2.51,0.07,3.77,0.1c-0.02,0.48-0.05,0.96-0.08,1.45
      C-2.59-1.29-2.3-0.53-2.55-0.18z"/>
    <path fill="#2F1715" d="M6.59-0.18C6.54-0.11,6.46-0.05,6.31,0C5.12-0.03,3.93-0.07,2.74-0.1C2.27-0.06,1.85-0.32,1.73-0.69
      C1.64-0.97,1.76-1.26,1.81-1.37C1.97-1.72,2.13-2.3,2.16-3.26c1.25,0.06,2.5,0.11,3.75,0.17C5.9-2.75,5.88-2.41,5.86-2.07
      C6.56-1.29,6.85-0.53,6.59-0.18z"/>
    <path fill="#FFFFFF" d="M3.89-70.6L3.89-70.6C3.89-70.59,3.89-70.59,3.89-70.6l-0.01,0.01c-0.67,0.88-1.34,1.77-2,2.65
      c-0.05-0.09-0.1-0.19-0.15-0.28l-0.33,0.33c0.14,0.23,0.28,0.45,0.42,0.68c0.62-0.88,1.24-1.76,1.85-2.64c0,0,0-0.01,0.01-0.01
      c-0.06,0.21-0.12,0.41-0.18,0.62l-2.19,7.69l-0.51,1.8v0l-0.4,1.39l0.17,9.38v0l0.03,1.8h-1.24l0.03-1.78l0.17-9.4l-0.41-1.42
      l-2.87-10.08c0.62,0.88,1.24,1.77,1.86,2.65c0.14-0.23,0.28-0.45,0.42-0.68l-0.33-0.33c-0.05,0.09-0.1,0.18-0.15,0.28
      c-0.67-0.89-1.34-1.77-2.01-2.66c0.44-0.34,0.87-0.67,1.31-1.01c0.06,0.03,0.11,0.06,0.16,0.09c0.62,0.35,1.25,0.7,1.88,1.05
      l-0.6,1.13c0.13,0.07,0.26,0.14,0.39,0.21c0.12-0.32,0.23-0.65,0.35-0.97c0.14,0.38,0.27,0.77,0.41,1.15
      c0.14-0.38,0.27-0.77,0.41-1.15c0.12,0.32,0.23,0.65,0.35,0.97c0.13-0.07,0.26-0.14,0.39-0.21l-0.6-1.13
      c0.62-0.35,1.25-0.7,1.87-1.05c0.06-0.03,0.11-0.07,0.17-0.1C3.02-71.27,3.45-70.94,3.89-70.6L3.89-70.6z"/>
    <g>
      <path fill="#E0E0E0" d="M-1.41-67.88c-0.14,0.23-0.28,0.45-0.42,0.68c-0.62-0.88-1.24-1.77-1.86-2.65
        c-0.07-0.25-0.14-0.5-0.21-0.75c0.67,0.89,1.34,1.77,2.01,2.66c0.05-0.09,0.1-0.19,0.15-0.28L-1.41-67.88z"/>
      <path fill="#E0E0E0" d="M-0.41-70.09c-0.12,0.32-0.23,0.65-0.35,0.97c-0.13-0.07-0.26-0.14-0.39-0.21l0.6-1.13L-0.41-70.09z"/>
    </g>
    <path fill="#5F3C15" d="M7.08-36.85C6.79-25.59,6.51-14.34,6.22-3.07c-0.1,0-0.2-0.01-0.31-0.01C4.66-3.14,3.41-3.2,2.16-3.26
      c-0.14-0.01-0.27-0.01-0.4-0.02C1.19-12.68,0.61-22.09,0.04-31.5c-0.87,9.33-1.74,18.66-2.61,28C-2.78-3.51-3-3.51-3.21-3.52
      c-1.26-0.03-2.51-0.07-3.77-0.1c-0.17,0-0.35-0.01-0.52-0.01c0.11-11.09,0.23-22.17,0.34-33.26c0.77,0.4,1.71,0.77,2.82,1
      c1.42,0.3,2.64,0.25,3.51,0.16l0.11-6.18l0.04-1.98l0.02-1.28h1.3l0.02,1.28l0.04,1.98l0.11,6.18c0.87,0.1,2.09,0.14,3.5-0.16
      C5.41-36.11,6.32-36.47,7.08-36.85z"/>
    <path fill="#181C20" d="M-12.91-38.11h3.26v0.1C-10.73-38.05-11.82-38.08-12.91-38.11z"/>
    <polygon fill="#181C20" points="-0.41,-58.35 -0.58,-48.94 -1.24,-50.15 -0.85,-58.85   "/>
    <polygon fill="#181C20" points="-0.41,-58.35 -0.85,-58.85 -5.03,-63.54 -3.89,-64.6 -5.43,-65.45 -5.18,-69.83 -3.89,-70.6 
      -3.68,-69.85 -0.82,-59.77   "/>
    <polygon fill="#FAE4A7" points="-0.41,-58.35 -0.85,-58.85 -5.03,-63.54 -3.89,-64.6 -5.43,-65.45 -5.18,-69.83 -3.89,-70.6 
      -3.68,-69.85 -0.82,-59.77   "/>
    <g>
      <g>
        <g>
          <path fill="#AC6F1A" d="M-0.58-48.94l-0.03,1.78l-0.02,1.48l-0.01,0.5l-0.02,1.28l-0.04,1.98l-0.11,6.18
            c-0.87,0.1-2.09,0.14-3.51-0.16c-1.11-0.23-2.05-0.6-2.82-1c-0.73-0.37-1.31-0.78-1.71-1.09c0.63-4.41,1.26-8.81,1.89-13.22
            l-0.82-3.22l-0.97,2.91l-0.61,13.51c-0.09,0-0.18-0.01-0.27-0.01v-0.1h-3.29c-0.1,0-0.21-0.01-0.32-0.01
            c-0.02-3.12-0.03-6.24-0.06-9.36c-0.05-0.73-0.08-1.51-0.08-2.31c0-1.29,0.07-2.49,0.18-3.58c0.39-3.62,0.77-7.24,1.16-10.86
            c0.02-0.27,0.09-0.82,0.44-1.39c0.33-0.54,0.75-0.86,0.98-1c1.81-1.07,3.62-2.14,5.43-3.2l-0.24,4.39l1.53,0.84l-1.14,1.06
            l4.18,4.7l-0.39,8.69L-0.58-48.94z"/>
        </g>
        <g>
          <path fill="#AC6F1A" d="M13.38-49.8c0,0.81-0.03,1.58-0.08,2.31c-0.02,3.12-0.04,6.24-0.06,9.36c-0.11,0-0.23,0.01-0.34,0.01
            H9.69v0.1c-0.1,0-0.21,0.01-0.32,0.01L8.76-51.51l-0.97-2.91L6.98-51.2c0.63,4.4,1.26,8.81,1.89,13.22
            c-0.42,0.32-1.02,0.74-1.79,1.13v0c-0.76,0.38-1.68,0.74-2.75,0.96c-1.41,0.3-2.63,0.25-3.5,0.16l-0.11-6.18L0.68-43.9
            l-0.02-1.28l-0.01-0.47l-0.03-1.51l-0.03-1.8v0l0.65-1.19l-0.38-8.68v0l4.19-4.71L3.89-64.6l1.54-0.84l-0.24-4.39
            c1.81,1.07,3.62,2.13,5.43,3.2c0.23,0.15,0.65,0.46,0.98,1c0.35,0.57,0.42,1.11,0.44,1.39c0.39,3.62,0.78,7.24,1.16,10.86
            C13.31-52.28,13.38-51.08,13.38-49.8z"/>
        </g>
      </g>
    </g>
    <polygon fill="#181C20" points="0.85,-58.84 1.23,-50.15 0.58,-48.97 0.41,-58.35   "/>
    <path fill="#181C20" d="M12.91-38.11c-1.07,0.03-2.14,0.06-3.21,0.1v-0.1H12.91z"/>
    <polygon fill="#181C20" points="3.89,-64.6 5.03,-63.54 0.85,-58.84 0.85,-58.84 0.41,-58.35 0.81,-59.74 0.81,-59.74 1.32,-61.54 
      3.68,-69.84 3.89,-70.59 3.89,-70.59 3.89,-70.6 3.89,-70.6 3.89,-70.59 5.18,-69.83 5.43,-65.45   "/>
    <polygon fill="#FAE4A7" points="3.89,-64.6 5.03,-63.54 0.85,-58.84 0.85,-58.84 0.41,-58.35 0.81,-59.74 0.81,-59.74 1.32,-61.54 
      3.68,-69.84 3.89,-70.59 3.89,-70.59 3.89,-70.6 3.89,-70.6 3.89,-70.59 5.18,-69.83 5.43,-65.45   "/>
    <rect x="-12.93" y="-38.11" fill="#FFFFFF" width="3.29" height="0.67"/>
    <rect x="9.69" y="-38.11" fill="#FFFFFF" width="3.29" height="0.67"/>
    <path fill="#F7B579" d="M4.51-79.98c-0.03,0.31-0.07,0.63-0.1,0.94c-0.09,0.84-0.18,1.67-0.28,2.51c-0.01,0.12-0.03,0.24-0.04,0.35
      c-0.03,0.3-0.06,0.59-0.1,0.89c-0.06,0.35-0.2,0.85-0.57,1.33c-0.08,0.11-0.17,0.21-0.26,0.3C2.58-73.08,2-72.52,1.41-71.96
      c-0.07,0.06-0.17,0.14-0.29,0.21c-0.07,0.04-0.14,0.06-0.2,0.08c-0.61-0.01-1.21-0.01-1.82-0.02c-0.07-0.01-0.16-0.03-0.27-0.08
      c-0.1-0.05-0.18-0.1-0.24-0.15c-0.7-0.69-1.4-1.38-2.1-2.06c-0.1-0.11-0.22-0.27-0.33-0.47c-0.14-0.26-0.2-0.5-0.23-0.67
      l-0.09-1.04l-0.05-0.55l-0.01-0.14v0l-0.19-2.17l-0.12-1.31l0.18-0.64l0.95-0.41l-0.34-0.25l-0.34-0.25l0.35-0.65l3.5,0.13
      l0.39-0.1l1.76-0.47h1.32l0.01,0.01l0.33,0.49l0.02,0.02l0.28,0.41l-0.77,0.54l1.21,0.59L4.51-79.98z"/>
    <path fill="#392617" d="M4.84-79.01c-0.04,0.23-0.09,0.44-0.13,0.65c-0.1,0.45-0.26,1.09-0.58,1.84c0.09-0.84,0.18-1.68,0.28-2.51
      c0.03-0.32,0.07-0.63,0.1-0.94l-0.19-0.94l-1.21-0.59l0.77-0.54L3.6-82.46l-0.02-0.02c0.02-0.02,0.03-0.03,0.05-0.05
      c0.23-0.24,0.32-0.58,0.23-0.9c-0.14-0.5-0.63-0.64-0.65-0.65c-0.36-0.07-0.9-0.13-1.52,0c-0.78,0.16-1.12,0.5-1.97,0.85
      c-0.22,0.09-0.5,0.19-0.83,0.28c-0.3,0.08-0.65,0.15-1.05,0.2l-1.56,0.2l-0.35,0.65l0.34,0.25l0.34,0.25l-0.95,0.41l-0.18,0.64
      l0.12,1.31l0.19,2.17c-0.24-0.53-0.5-1.26-0.65-2.16c0-0.02-0.01-0.05-0.01-0.07c-0.13-0.82-0.13-1.54-0.09-2.1
      c0.6-2.04,1.06-3.53,1.17-3.87c0.03-0.09,0.1-0.27,0.26-0.43c0.14-0.14,0.28-0.2,0.35-0.23c0.33-0.14,1.61-0.5,3.37-0.92
      c0.34-0.05,0.75-0.07,1.2-0.01c1.62,0.21,2.65,1.3,3.01,1.72c0.4,0.48,0.53,1.1,0.32,1.61c-0.15,0.37-0.44,0.58-0.58,0.67
      c0.11,0.12,0.27,0.33,0.43,0.62C5.1-81.03,5.02-79.96,4.84-79.01z"/>
    <g>
      <path fill="#301B1A" d="M0.66-80.1c0.01-0.07,0.03-0.12,0.06-0.17c0.41-0.64,2.65,0.16,2.95,0.27c0.01,0,0.08,0.05,0.08,0.12
        c0,0.08-0.1,0.13-0.12,0.14c-0.11,0.05-0.23,0-0.26-0.01c-0.54-0.17-1.26-0.3-2.05-0.16c-0.11,0.02-0.21,0.04-0.31,0.07
        c-0.01,0.01-0.12,0.07-0.23,0.01C0.69-79.87,0.64-79.98,0.66-80.1z"/>
      <ellipse fill="#1B1B24" cx="2.03" cy="-78.08" rx="0.49" ry="0.68"/>
      <path fill="#301B1A" d="M-0.67-80.1c-0.01-0.07-0.03-0.12-0.06-0.17c-0.41-0.64-2.65,0.16-2.95,0.27c-0.01,0-0.08,0.05-0.08,0.12
        c0,0.08,0.1,0.13,0.12,0.14c0.11,0.05,0.23,0,0.26-0.01c0.54-0.17,1.26-0.3,2.05-0.16c0.11,0.02,0.21,0.04,0.31,0.07
        c0.01,0.01,0.12,0.07,0.23,0.01C-0.7-79.87-0.65-79.98-0.67-80.1z"/>
      <ellipse fill="#1B1B24" cx="-2.04" cy="-78.08" rx="0.49" ry="0.68"/>
    </g>
    <path fill="#DD8553" d="M0.02-77.98c-0.12,0.52-0.06,0.86,0.04,1.08c0.05,0.12,0.14,0.28,0.11,0.49c-0.03,0.19-0.14,0.34-0.24,0.43
      c0.07,0.02,0.21,0.06,0.36,0.01c0.26-0.09,0.41-0.37,0.4-0.6c-0.01-0.24-0.19-0.31-0.39-0.63C0.12-77.49,0.05-77.78,0.02-77.98z"/>
    <path fill="#F0F7F9" d="M-1.74-74.89l3.42,0.09c-0.1,0.16-0.65,0.97-1.18,1.02c-0.05,0-0.25,0.02-0.49,0.01
      c-0.26-0.01-0.46-0.03-0.5-0.04C-1.03-73.9-1.65-74.77-1.74-74.89z"/>
    <path fill="#E79A58" d="M5.07-76.41c-0.32,0.25-0.71,0.27-0.97,0.24c0.01-0.12,0.03-0.24,0.04-0.35c0.32-0.75,0.49-1.39,0.58-1.84
      c0.04-0.21,0.09-0.43,0.13-0.65c0.07,0.02,0.13,0.05,0.18,0.07C5.7-78.54,5.82-77,5.07-76.41z"/>
    <path fill="#E79A58" d="M-4.15-76.16c-0.26,0.01-0.62-0.02-0.92-0.25c-0.75-0.59-0.64-2.13,0.04-2.53c0.04-0.02,0.1-0.05,0.16-0.07
      c0.15,0.89,0.41,1.62,0.65,2.16v0l0.01,0.14L-4.15-76.16z"/>
    <path fill="#2A1E15" d="M3.63-82.53c-0.01,0.02-0.03,0.03-0.05,0.05l-0.33-0.49l-0.01-0.01H1.93l-1.76,0.47l-0.39,0.1l-3.5-0.13
      l1.56-0.2c0.4-0.05,0.75-0.12,1.05-0.2c0.33-0.09,0.61-0.19,0.83-0.28c0.85-0.35,1.19-0.69,1.97-0.85c0.63-0.13,1.17-0.07,1.52,0
      c0.03,0.01,0.51,0.16,0.65,0.65C3.95-83.11,3.86-82.77,3.63-82.53z"/>
    <polygon fill="#E79957" points="3.6,-82.46 0.68,-81.95 -0.15,-81.57 -1.03,-81.88 -2.91,-81.88 -3.73,-81.64 -4.07,-81.89 
      -3.72,-82.54 -0.22,-82.41 0.17,-82.51 1.93,-82.98 3.25,-82.98 3.26,-82.97 3.59,-82.49   "/>
    <g>
      <path fill="#FFFFFF" d="M0.54-70.46l0.6,1.13c-0.13,0.07-0.26,0.14-0.39,0.21c-0.12-0.32-0.23-0.65-0.35-0.97L0.54-70.46z"/>
      <path fill="#FFFFFF" d="M3.89-70.6c-0.07,0.25-0.14,0.5-0.21,0.75c-0.62,0.88-1.24,1.77-1.86,2.65c-0.14-0.23-0.28-0.45-0.42-0.68
        l0.33-0.33c0.05,0.09,0.1,0.18,0.15,0.28C2.55-68.82,3.22-69.71,3.89-70.6z"/>
      <g>
        <path fill="#E0E0E0" d="M3.89-70.6c-0.07,0.25-0.14,0.5-0.21,0.75c-0.62,0.88-1.24,1.77-1.86,2.65
          c-0.14-0.23-0.28-0.45-0.42-0.68l0.33-0.33c0.05,0.09,0.1,0.18,0.15,0.28C2.55-68.82,3.22-69.71,3.89-70.6z"/>
        <path fill="#E0E0E0" d="M1.15-69.33c-0.13,0.07-0.26,0.14-0.39,0.21c-0.12-0.32-0.23-0.65-0.35-0.97l0.13-0.37L1.15-69.33z"/>
      </g>
    </g>
    <g>
      <path fill="#136EA2" d="M1.14-69.33c-0.38,0.21-0.77,0.42-1.15,0.63c-0.38-0.21-0.76-0.42-1.14-0.63
        c-0.2,0.37-0.39,0.74-0.59,1.11l1.24,1.23h0.05h0.88h0.05c0.42-0.41,0.83-0.82,1.25-1.23C1.54-68.58,1.34-68.96,1.14-69.33z"/>
      <polygon fill="#136EA2" points="1.23,-50.15 0.58,-48.97 0.58,-48.96 -0.01,-47.87 -0.58,-48.94 -1.24,-50.15 -0.85,-58.85 
        -0.82,-59.77 -0.5,-66.98 0.49,-66.98 0.81,-59.74 0.81,-59.74 0.85,-58.84 0.85,-58.84    "/>
    </g>
    <path fill="#FFFFFF" d="M3.36-71.31L3.36-71.31z"/>
    <polygon fill="#42281D" points="0.65,-45.18 -0.65,-45.18 -0.64,-45.69 -0.62,-47.17 0.62,-47.17 0.65,-45.65  "/>
    <path fill="#F7B579" d="M-10.02-37.45l0.05,1.04c0.05,0.04,0.09,0.09,0.14,0.14c0.46,0.51,0.53,1.18,0.52,1.58
      c0,0.11-0.01,0.29,0,0.52c0.01,0.37,0.05,0.51,0.02,0.78c-0.02,0.2-0.06,0.5-0.22,0.55c-0.1,0.03-0.22-0.04-0.29-0.11
      c-0.26-0.27,0.04-0.72-0.18-1.25c-0.07-0.17-0.18-0.29-0.24-0.36c-0.01,0.44,0.02,1.07,0.25,1.78c0.13,0.4,0.3,0.74,0.45,1.01
      c0.18,0.26,0.24,0.51,0.14,0.64c-0.1,0.12-0.31,0.1-0.41,0.08c-0.72-0.09-1.51-1.02-1.8-1.38c-0.18-0.33-0.36-0.73-0.51-1.2
      c-0.26-0.84-0.32-1.59-0.31-2.13c-0.01-0.57-0.03-1.13-0.04-1.7H-10.02z"/>
    <path fill="#F7B579" d="M10.26-37.45l-0.05,1.04c-0.05,0.04-0.09,0.09-0.14,0.14c-0.46,0.51-0.53,1.18-0.52,1.58
      c0,0.11,0.01,0.29,0,0.52c-0.01,0.37-0.05,0.51-0.02,0.78c0.02,0.2,0.06,0.5,0.22,0.55c0.1,0.03,0.22-0.04,0.29-0.11
      c0.26-0.27-0.04-0.72,0.18-1.25c0.07-0.17,0.18-0.29,0.24-0.36c0.01,0.44-0.02,1.07-0.25,1.78c-0.13,0.4-0.3,0.74-0.45,1.01
      c-0.18,0.26-0.24,0.51-0.14,0.64c0.1,0.12,0.31,0.1,0.41,0.08c0.72-0.09,1.51-1.02,1.8-1.38c0.18-0.33,0.36-0.73,0.51-1.2
      c0.26-0.84,0.32-1.59,0.31-2.13c0.01-0.57,0.03-1.13,0.04-1.7H10.26z"/>
    </g>
    <g v-if="type === 2" v-html="mySvg" id="mySvgId"> 
    </g>
    <g v-if="type === 3"> 
        <foreignObject width="120" height="120" class="myImgContainer">
          <img :src="imageUrl" alt="导航" class="myImg">
        </foreignObject>
    </g>
    </g>
  </template>
  
  <script>
  import { getSvgContent } from '@/api'
  export default ({
    name:"human",
    data(){
      return {
        type:3,
        mySvg:""
      }
    },
    props: {
      imageUrl: {
        type: String,
        default: `require(./../assets/img/b-car.png)`
      }
    },
    computed: {
  
    },
    created(){
    },
    mounted(){
      this.getSvg()
    },
    methods:{
      handleClick(){
        this.$emit("myHumanClick")
     },
     async getSvg(){
      // getSvgContent("https://roche-smallapps-api-noprod.roche.com.cn/common/s3download?filekey=b35ec300-513d-44a0-996a-f0faa128e9a9&fileName=%E9%92%A2%E9%93%81%E6%9C%BA%E5%99%A8%E4%BA%BA.svg").then(res => {
      //   console.log("res",res)
      // })
      const response = await fetch("https://roche-smallapps-api-noprod.roche.com.cn/common/s3download?filekey=b35ec300-513d-44a0-996a-f0faa128e9a9&fileName=%E9%92%A2%E9%93%81%E6%9C%BA%E5%99%A8%E4%BA%BA.svg")
      const svgText = await response.text()
      this.mySvg = svgText
      this.$nextTick(()=>{
        const mySvgId = document.querySelector("#mySvgId svg")
        if(mySvgId){
          mySvgId.setAttribute("width",80)
          mySvgId.setAttribute("height",80)
        }
      })
     }
   }
  })
  </script>
  
  <style>
  g svg{
    width: 49px;
    height: 49px;
  }
  .myImgContainer{
    text-align: center;
  }
  .myImg{
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
  }
  </style>
  