<template>
  <div class="container">
    <div> <a-icon type="left-circle" @click="backPage()" class="icons" /></div>
    <div class="morebox">
       <table class="tab">
        <thead>
          <tr>
            <td width="30%">个人</td>            
            <td width="30%">团队</td>
            <td width="40%">发布批次</td>
          </tr>
        </thead>
        <tbody>
          <template v-if="versionList.length>0">
            <tr  v-for="(item,index) in versionList">
            <td>{{item.scoreY}}</td>           
            <td>{{item.teamScoreY}}</td>
            <td>{{item.versionName}}</td>
          </tr>
          </template>          
          <tr v-else>
            <td colspan="3">没有历史版本</td>
          </tr>
        </tbody>
       </table>
    </div>
     <!--预加载动画-->
     <pageLoad :showLoader="showLoader" />      
    <a-modal  :footer="null" v-model:open="open" title="提示" centered>
      <p>小伙伴不要着急,您暂时还没有信用积分,敬请期待!</p>
    </a-modal>
  </div>
</template>
<script>
  import {getUserScoreDetail} from '@/api/personalCreadit.js'
  import pageLoad from './pageload/index.vue'
  export default{
    name:'creditmore',
    data()
    {
      return{
        //加载项
        showLoader:false,
        //弹出提示框窗口
        open:false,
       //历史信息
       versionList:[]
      }
    },
    created()
    {
      this.init()
    },
    components:{
      pageLoad,      
    },
    methods:{
      init()
      {
          this.showLoader=true
          getUserScoreDetail().then((res)=>{            
            const {data:{data:{ScoreCountMiddle,ScoreCountResult,UserInfo,ScoreCountResultHistory}}}=res;            
            this.versionList=ScoreCountResultHistory        
            this.open=ScoreCountResultHistory.length>0?false:true;
        }).finally(()=>{
          this.showLoader=false;
        })
      },
      backPage()
      {
        this.$router.push({path:'/personcredit',query:{flag:1}})
      }
    }
  }
</script>
<style lang="scss" scoped>

.container {
  display: flex;
  flex-direction: column;
  padding: 0.75rem;
  height: 100vh;
  background: url('../../assets/img/personcredit/back.png') bottom center
    #0666cc no-repeat;
  background-size: contain;
  .icons{
    font-size:35px;
    color:#fff;
    margin-left:24px;
  }
  .morebox{
    padding:1.8rem 2.5rem;
  }
  .tab{
    cellspacing:0px;
    width:100%;
    td{
      border:1px solid #fff;
      color:#fff;
      height: 30px;
      line-height: 30px;
      text-align: center;
    }
  }
 } 
</style>