<template>
    <div>
        <!--预加载动画-->
        <div class="page-loader" v-if="showLoader">
            <div class="page-loader-inner">
                <div class="spinner">
                    <div class="double-bounce1" />
                    <div class="double-bounce2" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'pageLoad',
    props: {
        showLoader: {
            type: Boolean,
            default:false,
        }
    },   
}
</script>
<style lang="scss" scoped>
.page-loader {
    background: #0c86d8;
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 999999;
    opacity: 1;
}

.double-bounce1,
.double-bounce2 {
    position: absolute;
    background-color: #68da17;
    border-radius: 50%;
    opacity: .5;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    animation: bounce 2s infinite ease-in-out;
}
</style>