<template>
  <div>
     <a href="javascript:;" v-if="language=='en'" id="changeLang" class="lang lang-en" data-lang="zh" @click="handleSetLanguage(language)">
       <div class="b-text" data-i18n="ZHEN.head">{{$t('ZHEN.head')}}</div>
     </a>
     <a href="javascript:;" v-if="language=='zh'" id="changeLang" class="lang" data-lang="zh" @click="handleSetLanguage(language)">
       <div class="b-text" data-i18n="ZHEN.head">{{$t('ZHEN.head')}}</div>
     </a>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import store from '@/store'
import $ from '@/plugins/jquery.vendor.js'
export default {
  name: 'SelectLanguage',
  computed: {
    ...mapGetters([
      'language'
    ])
  },
  mounted(){
    let that = this;
      if (that.language === 'en') {
            $('#changeLang').removeClass('lang-en');
            $('.b-text,.b-CEO').addClass('en');
        } else {
            $('#changeLang').addClass('lang-en');
        }
  },
  methods: {
    handleSetLanguage(lang) {
      if(lang == 'en'){
        lang = 'zh'
      }else{
        lang = 'en'
      }
      $('.b-text,.b-CEO').toggleClass('en');
      this.$i18n.locale = lang
      store.dispatch('setLanguage', lang)
    }
  }
}
</script>
<style scoped>
.b-text {
  position: absolute;
  color: #715935;
  z-index: 99999;
  text-align: center;
  width: 100%;
}
</style>
