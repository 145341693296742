<template>
  <div ref="container" class="container">
    <div class="header">
      <div class="logo">
        <div class="rochIcon">
          <img src="../../assets/img/personcredit/logo.png" />
        </div>
        <div class="icon">
          <img src="../../assets/img/personcredit/logo2.png" />
        </div>
      </div>
      <template v-if="open == false">
        <div class="credit" id="credit"></div>
        <div class="perscorebox">
          <div class="person">
            <span>所属团队信用分:</span>
            <span>{{ personInfo.teamScoreY }}</span>
          </div>
          <div class="person">
            <span>团队信用:</span>
            <span>{{ personInfo.teamGrade ? personInfo.teamGrade : '未评定' }}</span>
          </div>
          <div
            v-if="personInfo.comprehensive !== '' && personInfo.comprehensive !== null && personInfo.comprehensive !== 'N/A'"
            class="person">
            <span>团队综评奖金:</span>
            <span>{{ personInfo.comprehensive }}</span>
          </div>
        </div>
        <div class="faceIcon">
          <img :src="faceImag">
        </div>
        <div class="history">
          <a href="javascript:" @click="jump()" class="more">查看历史信用</a>
        </div>
        <div class="combine">
          <section class="left">合规拼图</section>
          <section class="right">:{{ personInfo.complianceExams === 'N/A' ? "" : personInfo.complianceExams }}</section>
        </div>
        <div class="combine">
          <section class="left">是否有口头警告</section>
          <section class="right">:{{ Number(personInfo.oralWarning) > 0 ? 'Y' : 'N' }}</section>
        </div>
        <div class="combine">
          <section class="left">未完成必须培训数</section>
          <section class="right">:{{ personInfo.notTraining }}次</section>
        </div>
        <div class="combine">
          <section class="left">不良记录情况</section>
          <section class="right"></section>
        </div>
      </template>
    </div>
    <template v-if="open == false">
      <div class="content">
        <div class="tabbox">
          <table class="tab">
            <template>
              <tr>
                <td width="50%">T&E退单:{{ personInfo.chargeback }}</td>
                <td width="50%">飞检:{{ personInfo.flightInspection }}</td>
              </tr>
              <tr>
                <td width="50%">文件未及时提交:{{ personInfo.notSubmitted }}</td>
                <td width="50%">合规教育:{{ personInfo.education }}</td>
              </tr>
            </template>
          </table>
        </div>
      </div>
    </template>
    <pageLoad :showLoader="showLoader" />
    <a-modal :footer="null" v-model:open="open" title="提示" :closable="false" :maskClosable="false" centered>
      <p>小伙伴不要着急,您暂时还没有信用积分,敬请期待!</p>
    </a-modal>
  </div>
</template>
<script>
import faceImag1 from '@/assets/img/personcredit/01.png';
import faceImag2 from '@/assets/img/personcredit/02.png';
import faceImag3 from '@/assets/img/personcredit/03.png';
import faceImag4 from '@/assets/img/personcredit/04.png';
import faceImag5 from '@/assets/img/personcredit/05.png';
import { Chart } from '@antv/g2'
import { getUserScoreDetail } from '@/api/personalCreadit.js';
import pageLoad from '../personalCredit/pageload/index';
const faceIcon = new Map();
faceIcon.set('极好', faceImag1)
faceIcon.set('优秀', faceImag2)
faceIcon.set('良好', faceImag3)
faceIcon.set('中等', faceImag4)
faceIcon.set('有待提高', faceImag5)

export default {
  name: 'personcredit',
  data() {
    return {
      //弹窗
      open: false,
      //加载动画
      showLoader: false,
      //个人详情
      personInfo: {
        //团队综评
        comprehensive: null,
        //合规拼图考试
        complianceExams: null,
        //口头警告
        oralWarning: null,
        //未完成培训
        notTraining: null,
        //T&E退单:
        chargeback: null,
        //飞检
        flightInspection: null,
        //未及时提交
        notSubmitted: null,
        //合规教育
        education: null,
        //团队信用
        teamGrade: null,
        //个人信用得分
        scoreY: null,
        //用户名
        userName: null,
        //团队得分
        teamsScoreY: null,
        //评级
        rate: "优秀",
        //得分明细
        scoreCountMiddle: [],
        //横竖屏的判断
        isLandscape: this.checkLandscape()
      },
      //笑脸图标
      faceImag: null,
    }
  },
  components: {
    pageLoad,
  },
  computed: {

  },
  created() {
    this.init()
    window.addEventListener('resize', () => {
           this.getDomheight();
    });
  },
  destroyed() {
    window.removeEventListener('resize', () => {});
  },
  methods: {
    init() {
      this.showLoader = true
      getUserScoreDetail().then((res) => {
        const { data: { data: { ScoreCountMiddle, ScoreCountResult, UserInfo } } } = res;
        this.open = ScoreCountResult && ScoreCountResult.length > 0 ? false : true;
        this.getDomheight();
        if (!this.open) {
          this.personInfo = {
            userName: UserInfo[0].userName,
            scoreY: ScoreCountResult[0].scoreY,
            teamScoreY: ScoreCountResult[0].teamScoreY,
            userGrade: ScoreCountResult[0].userGrade,
            teamGrade: ScoreCountResult[0].teamGrade,
            scoreCountMiddle: ScoreCountMiddle,
          }
          ScoreCountMiddle.forEach(item => {
            if (item.excelId == 14) {

              this.personInfo.notTraining = item.editRangeQ !== null && item.editRangeQ !== '' ? item.editRangeQ : item.scoreQ;

            }
            else if (item.excelId == 1) {
              this.personInfo.complianceExams = item.editTextQ !== null && item.editTextQ !== "" ? item.editTextQ : item.textScoreQ;
            }
            else if (item.excelId == 15) {
              this.personInfo.comprehensive = item.editTextQ !== null && item.editTextQ !== "" ? item.editTextQ : item.textScoreQ;
            }
            else if (item.excelId == 8 && item.ruleId == 25) {
              this.personInfo.oralWarning = item.editRangeQ !== null && item.editRangeQ !== '' ? item.editRangeQ : item.scoreQ;
            }
            else if (item.excelId == 2) {
              this.personInfo.chargeback = item.editRangeQ !== null && item.editRangeQ !== '' ? item.editRangeQ : item.scoreQ;
            }
            else if (item.excelId == 6) {
              this.personInfo.flightInspection = item.editRangeQ !== null && item.editRangeQ !== '' ? item.editRangeQ : item.scoreQ;
            }
            else if (item.excelId == 5) {
              this.personInfo.notSubmitted = item.editRangeQ !== null && item.editRangeQ !== '' ? item.editRangeQ : item.scoreQ;
            }
            else if (item.excelId == 8 && item.ruleId == 24) {
              this.personInfo.education = item.editRangeQ !== null && item.editRangeQ !== '' ? item.editRangeQ : item.scoreQ;
            }
          });
          this.drawChart(this.personInfo);
          this.faceImag = this.personInfo.userGrade ? faceIcon.get(this.personInfo.userGrade) : faceImag1;
         
        }
      }).finally(() => {
        this.showLoader = false;
      })
    },
    checkLandscape() {
      return window.innerWidth > window.innerHeight;
    },
    getDomheight() {
      this.$nextTick(() => {
        const app = document.querySelector("#app")
        const container = document.querySelector(".container");
        const containerHeight =  container.offsetHeight;
        const viewportHeight = window.innerHeight;
        app.style.height = '100%';
        if (viewportHeight > containerHeight) {

          container.style.height = '100%';
        }
        else {
          container.style.height = 'auto';
        }
      })
    },
    drawChart(personInfo) {
      // 极坐标下的柱状图
      // 构造数据

      const { scoreY, userName, userGrade } = personInfo;
      const userGradeBoard = userGrade ? userGrade : '未评定';
      const data1 = []
      const dataLeng = scoreY > 100 ? (scoreY + 10) : 101;
      for (let i = 0; i < dataLeng; i++) {
        data1.push({
          type: i + '',
          value: 10,
        })
      }

      const data2 = []
      for (let i = 0; i < dataLeng; i++) {
        const item = {}
        item.type = i + ''
        item.value = 10
        if (i == scoreY) {
          item.value = 14
        }
        if (i > scoreY) {
          item.value = 0
        }
        data2.push(item)
      }
      const chart = new Chart({
        container: 'credit',
        autoFit: true,
        height: 250,
        padding: 0,
      })
      chart.scale({
        type: {
          range: [0, 1],
        },
        value: {
          sync: true,
        },
      })
      chart.legend(false)
      chart.tooltip(false)

      const view1 = chart.view()
      view1.source(data1)
      view1.axis(false)
      view1.coord('polar', {
        startAngle: (-9 / 8) * Math.PI,
        endAngle: (1 / 8) * Math.PI,
        innerRadius: 0.75,
        radius: 0.8,
      })
      view1.interval().position('type*value').color('#CBCBCB').size(4)

      const view2 = chart.view()
      view2.source(data2)
      view2.axis('value', false)
      view2.axis('type', {
        grid: null,
        line: null,
        tickLine: null,
        label: {
          offset: -8,
          textStyle: {
            textAlign: 'center',
            fill: '#CBCBCB',
            fontSize: 10,
          },
          formatter: (val) => {
            if (+val % 10 !== 0) {
              return ''
            }

            return val
          },
        },
      })
      view2.coord('polar', {
        startAngle: (-9 / 8) * Math.PI,
        endAngle: (1 / 8) * Math.PI,
        innerRadius: 0.95,
        radius: 0.55,
      })
      view2.interval().position('type*value').color('#CBCBCB').size(6)

      const view3 = chart.view()
      view3.source(data2)
      view3.axis(false)
      view3.coord('polar', {
        startAngle: (-9 / 8) * Math.PI,
        endAngle: (1 / 8) * Math.PI,
        innerRadius: 0.75,
        radius: 0.8,
      })
      view3
        .interval()
        .position('type*value')
        .color('value', '#3023AE-#53A0FD')
        .size(6)
      view3.guide().text({
        position: ['50%', '65%'],
        content: personInfo.scoreY.toString(),
        style: {
          fill: '#fff',
          fontSize: 64,
          textAlign: 'center',
          textBaseline: 'middle',
        },
      })

      const view4 = chart.view()
      view4.source(data2)
      view4.guide().text({
        position: ['50%', '90%'],
        content: userGradeBoard,
        style: {
          fill: '#fff',
          fontSize: 20,
          textAlign: 'center',
          textBaseline: 'middle',
        },
      })

      const view5 = chart.view()
      view5.source(data2)
      view5.guide().text({
        position: ['50%', '10%'],
        content: userName,
        style: {
          fill: '#fff',
          fontSize: 20,
          textAlign: 'center',
          textBaseline: 'middle',
        },
      })

      chart.render()      
    },
    jump() {
      this.$router.push({ path: "/more", query: { flag: 1 } })
    }
  },

}
</script>
<style lang="scss" scoped>
.combine {
  display: flex;
  color: #fff;
  font-size: 14px;
  margin-bottom: 12px;

  .left {
    flex: 4;
    text-align: left;
    padding-left: 25px;
  }

  .right {
    flex: 6;
    text-align: left;
  }
}

.page-loader {
  background: #0c86d8;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 999999;
  opacity: 1;
}

.double-bounce1,
.double-bounce2 {
  position: absolute;
  background-color: #68da17;
  border-radius: 50%;
  opacity: .5;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  animation: bounce 2s infinite ease-in-out;
}

.container {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 700;
  padding: 0.75rem;
  background: url('../../assets/img/personcredit/back.png') 0px 558px #0666cc no-repeat;
  overflow: hidden;
  height: 100%;

  .history {
    text-align: center;
    margin: 10px 0px;

    .more {
      color: #fff;
    }
  }

  .tabbox {
    padding: 10px 20px;
    background: rgba(25, 25, 25, 0.2);
  }

  .tab {
    cellspacing: 0px;
    width: 100%;

    td {
      border: 1px solid #fff;
      color: #fff;
      height: 30px;
      line-height: 30px;
      text-align: center;
    }
  }

  .faceIcon {
    width: 20%;
    margin: 0px auto;

    img {
      width: 100%;
    }
  }

  .perscorebox {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    color: #fff;

    .person {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 10px;
    }
  }

  .credit {
    display: flex;
    justify-content: center;
  }

  .header {
    display: flex;
    flex-direction: column;
  }

  .content {}

  .logo {
    display: flex;
    justify-content: space-between;

    .rochIcon {
      &>img {
        width: 100%;
      }

      width: 20%;
    }

    .icon {
      &>img {
        width: 100%;
      }

      width: 20%;
    }
  }
}

@media screen and (max-device-width:375px) {
  .container {
    height: auto 
  }
}

@media screen and (orientation:portrait) {

  /* 竖屏*/
  .container {
    height: 100%;
  }

}

@media screen and (orientation: landscape) {

  /* 横屏 */
  .container {
    background-size: cover;
    height: auto;

    .faceIcon {
      width: 10%;
      margin: 0px auto;

      img {
        width: 100%;
      }
    }

    .logo {
      display: flex;
      justify-content: space-between;

      .rochIcon {
        &>img {
          width: 100%;
        }

        width: 10%;
      }

      .icon {
        &>img {
          width: 100%;
        }

        width: 10%;
      }
    }
  }
}
</style>
