import router from './router'
import store from './store'
import axios from "axios";
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
// import getPageTitle from '@/utils/get-page-title'
import { getUrlParameter, getLocalUrl, getCode, loadGigyaScript} from '@/utils/util.js'
import { getGigyaToken, isTokenExpired, getCodeFromUrl, redirectToLogin, setGigyaToken, getstatus, removestatus } from '@/utils/auth'
import {getCodeToken, getAppToken} from '@/api'
const { getEnvConfig } = require('@/settings')
const { appId, appSecret} = getEnvConfig()
// NProgress.configure({ showSpinner: false }) // NProgress Configuration

const whiteList = ['/403']

router.beforeEach(async(to, from, next) => {
  // start progress bar
   NProgress.start() 
  // set page title
  // document.title = getPageTitle(to.meta.title)

  // determine whether the user has logged in
  //1，判断是否有code
  //flag区分渠道，有flag的为微信
  //   let code =  getUrlParameter("code")
  //   let UToken = getGigyaToken()
  //   if (!UToken) {
  //     if(code){
  //        getAppToken(appId,appSecret).then( r =>{
  //        window.localStorage.setItem('Fund.AccessToken',r.Data.AccessToken)
  //        axios.defaults.headers.common["Authorization"] =
  //                     "Bearer " +   window.localStorage.getItem('Fund.AccessToken');
  //         getUserDetailByCode(code).then(res => {
  //           let uidsToken = "U"+res.Data.AccessToken
  //           setGigyaToken(uidsToken)
  //           next()
  //           location.reload()
  //         })    
  //     })
  //   }else{
  //     getCode()
  //     next()
  //   }
  // }else{
  //    setGigyaToken(UToken)
  //     next()
  // }
  let flag = getUrlParameter("flag")
  let token = getUrlParameter("dpjwt")
  let status = getstatus()
  // console.log("dpjwt",token)
  // console.log("flag",flag)
  if(flag){
    let code =  getUrlParameter("code")
    let UToken = getGigyaToken()
    if(token  && !status){
      let reg = new RegExp("#/home$","g")
      token = token.replace(reg,"")
      setGigyaToken("G"+token)
      removestatus()
      next()
    }else{
      if (!UToken) {
        if(code){
            // getCodeToken(code).then(res => {
            //   let uidsToken = "U"+res.codetoken
            //   setGigyaToken(uidsToken)
            //   next()
            //   location.reload()
            // })  
            debugger
          const res = await  getCodeToken(code)
          let uidsToken = "U"+res.codetoken
          setGigyaToken(uidsToken) 
          next({...to,replace:true})
      }else{
        getCode()
        next()
      }
    }else{
       setGigyaToken(UToken)
        next()
    }
  }
}else{
  loadGigyaScript(function(){
  const accessToken = getGigyaToken()
  // 有token并且没过期
  if(token  && !status){
    let reg = new RegExp("#/home$","g")
    token = token.replace(reg,"")
    setGigyaToken("G"+token)
    removestatus()
    next()
  }else{
    if (accessToken && !isTokenExpired()) {
      setGigyaToken(accessToken)
      removestatus()
      next()
    }else {
    if (whiteList.indexOf(to.path) !== -1) {
      next()
    } else {
        removestatus()
        redirectToLogin()
        gigya.socialize.addEventHandlers({
          onLogin: async function(response) {          
            if (response.user.isLoggedIn) {
              await store.dispatch('login', JSON.stringify(response))
              // next({ ...to, replace: true })
              next()
              location.reload()
            }
          }
        })
    }
  }
  }
  })
} 
})

router.afterEach(() => {
  // finish progress bar
   NProgress.done()
})
