<template>
  <!-- 目录 -->
  <div id="Catalog" class="b-modal catalogModal">
    <a href=" " data-fancybox-close class="m-close">
      <div class="fancybox-close-text" title="关闭">{{ $t('Fancbox.close') }}</div>
    </a>
    <div class="modal-inner">
      <h2 class="m-header" data-i18n="Catalog.head">
        {{ $t('Catalog.head') }}
      </h2>
    </div>
    <div class="m-body">
      <a-input-search style="margin-bottom: 8px" placeholder="Search" @change="onChange" @blur="onBlur" />
      <a-spin tip="正在请求链接...请稍后" :spinning="loading">
        <a-tree :expanded-keys="expandedKeys" :auto-expand-parent="autoExpandParent" :tree-data="treeData"
          default-expand-all @expand="onExpand">
          <template v-slot:title="{ scopedSlots }">
            <span v-if="$t(scopedSlots.title).indexOf(searchValue) > -1"
              @click="openClick(scopedSlots.href, scopedSlots.anchor, scopedSlots.itemid, scopedSlots.type)">
              {{ $t(scopedSlots.title).substr(0, $t(scopedSlots.title).indexOf(searchValue)) }}
              <span
                style=" background-color: rgb(250,230,193);-webkit-box-shadow: 10px 10px 99px 6px rgba(238, 188, 22, 0.87);">{{
                  searchValue }}</span>
              {{ $t(scopedSlots.title).substr($t(scopedSlots.title).indexOf(searchValue) + searchValue.length) }}
            </span>
            <span v-else
              @click="openClick(scopedSlots.href, scopedSlots.anchor, scopedSlots.itemid, scopedSlots.type)">{{ $t(scopedSlots.title) }}</span>

          </template>
        </a-tree>
      </a-spin>
    </div>
  </div>
</template>
<script>

import { downloadFile, getCatalog, accesslog, searchLog } from '@/api'
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      expandedKeys: [],
      searchValue: '',
      autoExpandParent: true,
      treeData: [],
      dataList: []
    };
  },
  computed: {
    ...mapGetters([
      'loading'
    ])
  },
  created() {
    let that = this;
    getCatalog().then(res => {
      // console.log("2.json",res.data);
      that.allData = res.data;
      // console.log("allData",that.allData);
      let res2 = that.generateData(that.allData)
      let res3 = that.getSecondData(res2)
      // console.log("res3",res3);
      that.treeData = res3;
      that.generateList(res3)
      // console.log("dataList",that.dataList);

    })
    // this.$axios.get('/mockdata/2.json').then(res => {         
    //    console.log("2.json",res.data);
    //    that.allData = res.data;
    //    console.log("allData",that.allData);
    //    let res2 = that.generateData(that.allData)
    //    console.log("res2",res2);
    //    that.treeData = res2;
    //    that.generateList(res2)
    //    console.log("dataList",that.dataList);
    //  })   
  },
  methods: {
    getSecondData(data) {
      let result = []
      data.map(res => {
        if (res.children) {
          res.children.map(r => {
            result.push(r)
          })
        }
      })
      return result
    },
    onExpand(expandedKeys) {
      this.expandedKeys = expandedKeys;
      this.autoExpandParent = false;
    },
    onSelect(selectedKeys, info) {
      // console.log('selected', selectedKeys, info);
    },
    onCheck(checkedKeys, info) {
      // console.log('onCheck', checkedKeys, info);
    },
    logCatalog(url, itemid) {
      let accessdate = this.$moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
      let category = ""
      if (url.indexOf(".") > 0) {
        let index = url.indexOf(".");
        category = url.substring(0, index);
      }
      accesslog({ accessdate, category, itemid }).then(res => {
        // console.log(res);
      })
    },
    openClick(url, anchor, itemid, type) {
      let that = this;
      if (anchor) {
        $.fancybox.open($(anchor), {
          "modal": false
        })
        $(anchor + " .fancybox-close-small").hide()
      } else {
        let copyUrl = this.$i18n.tc(url)
        let accessdate = this.$moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
        let category = ""
        let index = url.indexOf(".");
        category = url.substring(0, index);
        let type2 = this.$i18n.tc(type)
        if (copyUrl && copyUrl !== url) {
          // alert("资源缺省，请上传对应资源")
          //type:1:外部链接，2:本地上传 3：内部链接
          if (type2 === "3") {
            this.isVpnflag = true;
            this.$nextTick(() => {
              that.$confirm({
                title: that.$i18n.tc('LinksFor.title'),
                content: that.$i18n.tc('LinksFor.message'),
                okText: that.$i18n.tc('LinksFor.okText'),
                cancelText: that.$i18n.tc('LinksFor.cancelText'),
                onOk() {
                  if (!window.open(copyUrl, '_blank')) { // popup blocked, offer direct download:
                    if (confirm("Displaying New Document\n\nUse Save As... to download, then click back to return to this page.")) { location.href = copyUrl; }
                  }
                },
                onCancel() { },
                zIndex: 99999
              });
            })
          } else if (type2 === "2") {
            // console.log(copyUrl);
            downloadFile(itemid)
          } else {
            if (!window.open(copyUrl, '_blank')) { // popup blocked, offer direct download:
              if (confirm("Displaying New Document\n\nUse Save As... to download, then click back to return to this page.")) { location.href = copyUrl; }
            }
          }
        } else {
          if (category === "Bus" && itemid === 208) {
            $.fancybox.open($("#drivingLicense"))
            $("#drivingLicense .fancybox-close-small").hide()
          } else {
            if (!that.$i18n.te(url)) {
              let i18n = that.$i18n.locale
              let lang = i18n == "en" ? "zh" : "en"
              if (that.$i18n.te(url, lang)) {
                let u = that.$i18n.t(url, lang)
                let t = that.$i18n.t(type, lang)
                if (t === "3") {
                  this.isVpnflag = true;
                  this.$nextTick(() => {
                    that.$confirm({
                      title: that.$i18n.tc('LinksFor.title'),
                      content: that.$i18n.tc('LinksFor.message'),
                      okText: that.$i18n.tc('LinksFor.okText'),
                      cancelText: that.$i18n.tc('LinksFor.cancelText'),
                      onOk() {
                        if (!window.open(u, '_blank')) { // popup blocked, offer direct download:
                          if (confirm("Displaying New Document\n\nUse Save As... to download, then click back to return to this page.")) { location.href = u; }
                        }
                      },
                      onCancel() { },
                      zIndex: 99999
                    });
                  })
                } else if (t === "2") {
                  downloadFile(itemid)
                } else {
                  if (!window.open(u, '_blank')) { // popup blocked, offer direct download:
                    if (confirm("Displaying New Document\n\nUse Save As... to download, then click back to return to this page.")) { location.href = u; }
                  }
                }
              } else {
                alert("中英文资源缺省，请上传对应资源")
              }
            }
          }
        }
        accesslog({ accessdate, category, itemid }).then(res => {
          // console.log(res);
        })
      }
    },
    openAnchor(anchor) {
      // console.log("anchor",anchor);
      // $.fancybox.open( $(anchor))
      // $(anchor+ " .fancybox-close-small").hide()
      // console.log("anchor");
    },
    setStyleDisplay(key, nodes) {
      let that = this;
      let nums = 0
      for (let data of nodes) {
        let bl = false
        if (that.$t(data.scopedSlots.title).indexOf(key) > -1) {
          bl = true
          nums += 1
        }
        if (!!data.children) {
          if (data.children.length > 0) {
            let count = that.setStyleDisplay(key, data.children)
            // 如果想 子节点有key 即使父节点没有key也显示的话 添加下面的语句 
            // nums += count
            if (!bl && count == 0) {
              bl = false
            } else {
              bl = true
            }
          }
        }
        if (bl) {
          delete data['style']
        } else {
          data['style'] = 'display: none'
        }
      }
      return nums
    },
    generateData(data) {
      let that = this;
      let tns = [];
      data.map((item, index) => {
        tns.push({ key: index, scopedSlots: { title: item.headers } });
        if (data[index].links && data[index].links.length > 0) {
          let Links = data[index].links;
          tns[index].children = [];
          Links.map((it, idx) => {
            tns[index].children.push({ key: `${index}-${idx}`, scopedSlots: { title: it.headers, anchor: it.anchor } });
            let Urls = Links[idx].links;
            tns[index].children[idx].children = [];
            Urls.map((i, ix) => {
              tns[index].children[idx].children.push({ key: `${index}-${idx}-${ix}`, scopedSlots: { title: i.title, href: i.url, itemid: i.itemid, type: i.type } });
            })
          })
        }
      })
      return tns
    },
    getParentKey(key, tree) {
      let that = this;
      let parentKey;
      for (let i = 0; i < tree.length; i++) {
        const node = tree[i];
        if (node.children) {
          if (node.children.some(item => item.key === key)) {
            parentKey = node.key;
          } else if (that.getParentKey(key, node.children)) {
            parentKey = that.getParentKey(key, node.children);
          }
        }
      }
      return parentKey;
    },
    generateList(data) {
      let that = this;
      for (let i = 0; i < data.length; i++) {
        const node = data[i];
        const key = node.key;
        const title = that.$t(node.scopedSlots.title)
        that.dataList.push({ key, title });
        if (node.children) {
          that.generateList(node.children);
        }
      }
    },
    onChange(e) {
      let that = this;
      const value = e.target.value;
      const expandedKeys = that.dataList
        .map(item => {
          if (item.title.indexOf(value) > -1) {
            return that.getParentKey(item.key, that.treeData);
          }
          return null;
        })
        .filter((item, i, self) => item && self.indexOf(item) === i);
      // 隐藏 不需要的选项
      // that.setStyleDisplay(value, that.treeData)
      Object.assign(this, {
        expandedKeys,
        searchValue: value,
        autoExpandParent: true,
      });
    },
    onBlur(e){
      const value = e.target.value;
      searchLog(value)
    }
  }
};
</script>
<style>
.catalogModal {
  padding: 4vh 6.347656vh 1.765625vh;
}

.ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected {
  border: 0;
  background-color: #f0a80cde;
  -webkit-box-shadow: 10px 10px 99px 6px rgba(238, 188, 22, 0.87);
  -moz-box-shadow: 10px 10px 99px 6px rgba(238, 188, 22, 0.87);
  box-shadow: 10px 10px 99px 6px rgba(238, 188, 22, 0.863);
}

.ant-tree li .ant-tree-node-content-wrapper:hover {
  color: white;
  border: 0;
  background-color: #e7b40e;
  -webkit-box-shadow: 10px 10px 99px 6px rgba(238, 188, 22);
  -moz-box-shadow: 10px 10px 99px 6px rgb(238, 188, 22);
  box-shadow: 10px 10px 99px 6px rgb(238, 188, 22);
}

.ant-tree-title {
  font: italic 500 2.3vh 'Microsoft yahei';
}
</style>