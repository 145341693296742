<template>
    <div id="MyVideo" class="b-modal changeWidth">
    <a href="javascript:;" data-fancybox-close class="m-close getP">
      <span class="fancybox-close-text" title="关闭">{{$t('Fancbox.close')}}</span>
    </a>
    <div class="m-body">
         <!-- 弹窗 -->
      <div class="videomasks" v-if="videoState == true">
      <!-- 视频：h5的视频播放video -->
      <video controls width="100%" height="100%">
        <source :src="videoSrc" type="video/mp4">
        <source :src="videoSrc" type="video/ogg">
        <source :src="videoSrc" type="video/webm">
      </video>
    </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import store from '@/store'

export default {
  name: 'MyVideo',
   computed: {
    ...mapGetters([
      'videoSrc',
      'videoState'
    ])
  },
  methods: {
    bofang() {},
    masksCloseFun() {
     store.dispatch('setVideoState', false)
    },
  }
};
</script>
<style scoped>
.mask {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 999;
  background-color: #000000;
  opacity: 0.6;
}
/* 内容层 z-index要比遮罩大，否则会被遮盖 */
.videomasks {
  display: contents;
  max-width: 1200px;
  width: 70vw;
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 999999;
  transform: translate(-50%, -50%); 
}
.getP{
  z-index: 999999
}
</style>