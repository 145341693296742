import { setCookieLanguage } from '@/utils/util'
import { getLanguage } from '@/locale'

const app = {
  state: {
    loading: false,
    language: getLanguage(),
    clear: 0,
    remember: false,
    edit: false,
    videoSrc:'',
    videoState:false
  },

  mutations: {
    SET_LOADING: (state, loading) => {
      state.loading = loading
    },
    SET_LANGUAGE: (state, language) => {
      state.language = language
    },
    SET_CLEAR: (state, clear) => {
      state.clear = clear
    },
    SET_REMEMBER: (state, remember) => {
      state.remember = remember
    },
    SET_EDIT: (state, edit) => {
      state.edit = edit
    },
    SET_VIDEOSRC: (state, videoSrc) => {
      state.videoSrc = videoSrc
    },
    SET_VIDEOSTATE: (state, videoState) => {
      state.videoState = videoState
    }
  },

  actions: {
    setLoading({ commit }, loading) {
      commit('SET_LOADING', loading)
    },
    setLanguage({ commit }, language) {
      commit('SET_LANGUAGE', language)
      setCookieLanguage(language)
    },
    setClear({ commit }, clear) {
      commit('SET_CLEAR', clear)
    },
    setRemember({ commit }, remember) {
      commit('SET_REMEMBER', remember)
    },
    setEdit({ commit }, edit) {
      commit('SET_EDIT', edit)
    },
    setvideoSrc({ commit }, videoSrc) {
      commit('SET_VIDEOSRC', videoSrc)
    },
    setVideoState({ commit }, videoState) {
      commit('SET_VIDEOSTATE', videoState)
    },
  }
}

export default app
