import request, { robotRequest } from '@/utils/request'
import axios from "axios";
import store from '@/store'
const { getEnvConfig } = require('@/settings')
const { apiBaseUrl} = getEnvConfig()

export function getUser(key) {
  return new Promise((resolve, reject) => {
    request({
      url: 'system/comm/user/info',
      method: 'GET'
    }).then(res => {
      const { data } = res
      resolve(data)
    }).catch(xhr => {
      reject(xhr)
    })
  })
}
export function getAppToken(appId,appSecret){
  return new Promise((resolve, reject) => {
    axios({
      url: `${apiBaseUrl}/uids/api/token?appId=${appId}&appSecret=${appSecret}`,
      method: 'GET'
    }).then(res => {
      const { data } = res
      resolve(data)
    }).catch(xhr => {
      reject(xhr)
    })
  })
}
export function getUserDetailByCode(code){
  return new Promise((resolve, reject) => {
    axios({
      url: `${apiBaseUrl}/uids/api/token/code?code=${code}`,
      method: 'GET'
    }).then(res => {
      const { data } = res
      resolve(data)
    }).catch(xhr => {
      reject(xhr)
    })
  })
}
function downloadUtil(key,filecode){
  store.dispatch("setLoading",false)
   let language = store.state.app.language
  language = language == "zh"?"cn":"en"
  let url = process.env.VUE_APP_BASE_API + '/common/s3download?filecode='+filecode
   if(!window.open(url)){ // popup blocked, offer direct download:
              if(confirm("Displaying New Document\n\nUse Save As... to download, then click back to return to this page.")){ location.href=url; }
        }
}
export function downloadFile (key) {
  // alert("测试，8-25")
  //  let language = store.state.app.language
  // language = language == "zh"?"cn":"en"
  // let url = process.env.VUE_APP_BASE_API + '/common/s3download?filekey='+key+'&language='+language
  //  if(!window.open(url)){ // popup blocked, offer direct download:
  //             if(confirm("Displaying New Document\n\nUse Save As... to download, then click back to return to this page.")){ location.href=url; }
  //       }

  let language = store.state.app.language
  language = language == "zh"?"cn":"en"
  store.dispatch("setLoading",true)
  let tes2 = process.env.VUE_APP_BASE_API + '/common/s3download?filekey='+key+'&language='+language
  // console.log("tes2",tes2)
  robotRequest({
    url: '/common/s3fileinfo?itemid='+key+'&language='+language,
    method: 'GET'
  })
    .then(res => {
      if (!res.data) {
        return
      } 
      if(res.headers.name){
        let h = res.headers.name.substring(res.headers.name.lastIndexOf('.') + 1)
        if( h.toLowerCase() == "mp4"){
              $.fancybox.open( 
                   $("#MyVideo"),{
                      modal:false
                    })
             $("#MyVideo .fancybox-close-small").hide()
            store.dispatch("setLoading",false)
            store.dispatch('setvideoSrc', tes2)
            store.dispatch('setVideoState', true)
        }else{
          downloadUtil(key,res.data.filecode)
        }
      }else{
        downloadUtil(key,res.data.filecode)
      }  
    })
    .catch(res => {store.dispatch("setLoading",false)})
  // let language = store.state.app.language
  // language = language == "zh"?"cn":"en"
  // store.dispatch("setLoading",true)
  // robotRequest({
  //   url: '/common/s3download?filekey='+key+'&language='+language,
  //   method: 'GET'
  // })
  //   .then(res => {
  //     if (!res.data) {
  //       return
  //     } 
  //     store.dispatch("setLoading",false)
  //     download(new Blob([res.data]),res.headers.name)
  //     // if(res.data.code == "200"){
  //     //   let url = process.env.VUE_APP_BASE_API + res.data.url
  //     //   if(!window.open(url)){ // popup blocked, offer direct download:
  //     //         if(confirm("Displaying New Document\n\nUse Save As... to download, then click back to return to this page.")){ location.href=url; }
  //     //   }
  //     // }else{
  //     //   alert("服务器500错误")
  //     // } 
  //     // download(new Blob([res.data]),res.headers.name)
  //     // let url = window.URL.createObjectURL(new Blob([res.data]))
  //     // window.opne(url)
  //     // let link = document.createElement('a')
  //     // link.style.display = 'none'
  //     // link.href = url
  //     // link.setAttribute('download', res.headers.name)
  //     // document.body.appendChild(link)
  //     // link.click()
  //     // // 释放URL对象所占资源
  //     // window.URL.revokeObjectURL(url)
  //     // // 用完即删
  //     // document.body.removeChild(link)
      
  //   })
  //   .catch(res => {store.dispatch("setLoading",false)})
}

export function accesslog({ accessdate, category, itemid}) {
  return new Promise((resolve, reject) => {
    request({
      url: 'system/comm/category/item/accesslog',
      method: 'POST',
      data: JSON.stringify({ accessdate, category, itemid })
    }).then(res => {
      const { data } = res
      resolve(data)
    }).catch(xhr => {
      reject(xhr)
    })
  })
}


export function getDownloadBase64(key) {
   let language = store.state.app.language
  language = language == "zh"?"cn":"en"
  return new Promise((resolve, reject) => {
    request({
      url: '/common/s3downloadbase64?filekey='+key+'&language='+language,
      method: 'GET'
    }).then(res => {
      const { data } = res
      resolve(data)
    }).catch(xhr => {
      reject(xhr)
    })
  })
}

export function getFileInfo(key) {
   let language = store.state.app.language
  language = language == "zh"?"cn":"en"
  return new Promise((resolve, reject) => {
    request({
      url: '/common/s3fileinfo?filekey='+key+'&language='+language,
      method: 'GET'
    }).then(res => {
      const { data } = res
      resolve(data)
    }).catch(xhr => {
      reject(xhr)
    })
  })
}
export function searchItems(key) {
  return new Promise((resolve, reject) => {
    request({
      url: `system/comm/category/items/search/${encodeURIComponent(key)}`,
      method: 'GET'
    }).then(res => {
      const { data } = res
      resolve(data)
    }).catch(xhr => {
      reject(xhr)
    })
  })
}

export function getIteminfos() {
  return new Promise((resolve, reject) => {
    request({
      url: 'system/comm/comp/iteminfos',
      method: 'GET'
    }).then(res => {
      const { data } = res
      resolve(data)
    }).catch(xhr => {
      reject(xhr)
    })
  })
}

export function getCatalog() {
  return new Promise((resolve, reject) => {
    request({
      url: 'system/comm/catalog/items',
      method: 'GET'
    }).then(res => {
      const { data } = res
      resolve(data)
    }).catch(xhr => {
      reject(xhr)
    })
  })
}

export function getAnnouncement() {
  return new Promise((resolve, reject) => {
    request({
      url: 'system/comm/sys/updateinfo',
      method: 'GET'
    }).then(res => {
      const { data } = res
      resolve(data)
    }).catch(xhr => {
      reject(xhr)
    })
  })
}
export function getDrivingLicense() {
  return new Promise((resolve, reject) => {
    request({
      url: 'system/comm/user/certifications',
      method: 'GET'
    }).then(res => {
      const { data } = res
      resolve(data)
    }).catch(xhr => {
      reject(xhr)
    })
  })
}
export function getDrivingLicenseImg() {
  return new Promise((resolve, reject) => {
    request({
      url: 'system/comm/certifications',
      method: 'GET'
    }).then(res => {
      const { data } = res
      resolve(data)
    }).catch(xhr => {
      reject(xhr)
    })
  })
}

export function getEn() {
  return new Promise((resolve, reject) => {
    request({
      url: 'system/comm/en',
      method: 'GET'
    }).then(res => {
      const { data } = res
      resolve(data)
    }).catch(xhr => {
      reject(xhr)
    })
  })
}

export function getCn() {
  return new Promise((resolve, reject) => {
    request({
      url: 'system/comm/cn',
      method: 'GET'
    }).then(res => {
      const { data } = res
      resolve(data)
    }).catch(xhr => {
      reject(xhr)
    })
  })
}

export function getMobileoute() {
  return new Promise((resolve, reject) => {
    request({
      url: 'system/comm/getMobileroute',
      method: 'GET'
    }).then(res => {
      const { data } = res
      resolve(data)
    }).catch(xhr => {
      reject(xhr)
    })
  })
}

export function sendFeedback({ content, createdtime }) {
  return new Promise((resolve, reject) => {
    request({
      url: 'system/comm/user/feedback',
      method: 'POST',
      data: JSON.stringify({ content, createdtime })
    }).then(res => {
      const { data } = res
      resolve(data)
    }).catch(xhr => {
      reject(xhr)
    })
  })
}

export function login({ email, password }) {
  return new Promise((resolve, reject) => {
    request({
      url: '/access/userlogin',
      method: 'POST',
      data: JSON.stringify({ email, password }),
      ignore: true
    }).then(res => {
      const { data } = res
      resolve(data)
    }).catch(xhr => {
      reject(xhr)
    })
  })
}

export function register(data) {
  return new Promise((resolve, reject) => {
    request({
      url: '/user/register',
      method: 'POST',
      data: JSON.stringify(data),
      ignore: true
    }).then(res => {
      const { data } = res
      resolve(data)
    }).catch(xhr => {
      reject(xhr)
    })
  })
}

export function getUserInfo(email) {
  return new Promise((resolve, reject) => {
    request({
      url: `/user/chosebyemail?email=${encodeURIComponent(email)}`,
      method: 'POST',
      data: null
    }).then(res => {
      const { data } = res
      resolve(data)
    }).catch(xhr => {
      reject(xhr)
    })
  })
}

export function updateUserInfo(data) {
  return new Promise((resolve, reject) => {
    request({
      url: '/user/updateuser',
      method: 'POST',
      data: JSON.stringify(data)
    }).then(res => {
      const { data } = res
      resolve(data)
    }).catch(xhr => {
      reject(xhr)
    })
  })
}

export function getLocationList() {
  return new Promise((resolve, reject) => {
    request({
      url: '/user/getLocation',
      method: 'POST',
      data: null,
      ignore: true
    }).then(res => {
      const { data } = res
      resolve(data)
    }).catch(xhr => {
      reject(xhr)
    })
  })
}

export function searchLog(key) {
  return new Promise((resolve, reject) => {
    request({
      url: `system/comm/catalogue/search/${encodeURIComponent(key)}`,
      method: 'POST'
    }).then(res => {
      const { data } = res
      resolve(data)
    }).catch(xhr => {
      reject(xhr)
    })
  })
}

export function downloadBlob(){
  return request({
    url:"system/comm/common/s3download",
    method:"get",
    responseType:"blob"
  })
}

export function getCodeToken(code) {
  return new Promise((resolve, reject) => {
    request({
      url: `system/comm/getcodetoken?code=${code}`,
      method: 'GET'
    }).then(res => {
      const { data } = res
      resolve(data)
    }).catch(xhr => {
      reject(xhr)
    })
  })
}

export function sendfinish(planid) {
  return new Promise((resolve, reject) => {
    request({
      url: `system/comm/sendfinish?planid=${planid}`,
      method: 'POST',
    }).then(res => {
      const { data } = res
      resolve(data)
    }).catch(xhr => {
      reject(xhr)
    })
  })
}

export function getImageinfo() {
  return new Promise((resolve, reject) => {
    request({
      url: 'system/comm/imageinfo',
      method: 'GET'
    }).then(res => {
      const { data } = res
      resolve(data)
    }).catch(xhr => {
      reject(xhr)
    })
  })
}