import moment from 'moment'
// import { getUserInfo } from '@/api'
import store from '@/store'

import {
  getEmail,
  setEmail,
  removeEmail,
  getToken,
  setToken,
  removeToken,
  getTokenExpiresTime,
  getWelcome
} from '@/utils/util'

import {
  setGigyaToken,
  setExpiredTime,
  setcurUserAd,
  redirectToLogin,
  setAppToken,
  clearCache
} from '@/utils/auth'
import {Message} from 'ant-design-vue';

const user = {
  state: {
    email: null,
    token: null,
    user: {
      name: '',
      ad: '',
      legalEntity: '',
      division: '',
      department: ''
  },
    registerEmail: null,
    password: null
  },

  mutations: {
    SET_EMAIL: (state, email) => {
      state.email = email
    },
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_USER: (state, user) => {
      state.user = user
    },
    SET_REGISTER_EMAIL: (state, registerEmail) => {
      state.registerEmail = registerEmail
    },
    SET_PASSWORD: (state, password) => {
      state.password = password
    }
  },

  actions: { 
  login({ commit }, code) {
    return new Promise(async(resolve) => {
     // alert(code)
      try {
        // const {
        //  Data: { AccessToken: AccessToken }
        // } =await getAppToken()
        
        // setAppToken(AccessToken);
        // 获取acces token
        //const {
        //  Data: { AccessToken: accessToken, Expires }
        //} = await getAccessToken(code, appToken)
        // console.log("code",code)
        var accessToken = "G"+JSON.parse(code).id_token 
        setGigyaToken(accessToken) 
        setcurUserAd(JSON.parse(code).user.loginProviderUID) 
        const millseconds = new Date().getTime()
        var Expires=7 * 24 * 60
        const expiredTime = millseconds + Expires * 60 * 1000
        setExpiredTime(expiredTime)
        resolve(accessToken)
        //debugger
        //var xauthresult= await getXAUTHTOKEN()
        //setXAUTHTOKEN(xauthresult.Data) 

      } catch (error) { 
        Message.error(error.message);
        // Message({ type: 'error', message: `登录失败, ${error.message}` })
        clearCache()
        // console.log(error.message)
        redirectToLogin()
      }
    })
},
    setEmail({ commit }, { email, expires }) {
      if (email) {
        setEmail({ email, expires })
      } else {
        removeEmail()
      }
      commit('SET_EMAIL', email)
    },
    setToken({ commit }, { token, expires }) {
      if (token) {
        setToken({ token, expires })
      } else {
        removeToken()
      }
      commit('SET_TOKEN', token)
    },
    getCookie({ commit }) {
      return new Promise((resolve) => {
        const email = getEmail()
        const token = getToken()
        const welcome = getWelcome()
        if (email && token) {
          resolve({ email, token, welcome })
        } else {
          resolve()
        }
      })
    },
    setLoginState({ commit }, { email, token, expires: exp }) {
      var expires = exp
      if (!expires) {
        expires = getTokenExpiresTime(token)
        if (expires && typeof expires === 'number') {
          expires = moment(expires * 1000).format()
        }
      }
      user.actions.setEmail({ commit }, { email, expires })
      user.actions.setToken({ commit }, { token, expires })
    },
    logout({ commit }) {
      return new Promise((resolve) => {
        user.actions.setLoginState({ commit }, { email: null, token: null })
        resolve()
      })
    },
    // getUser({ commit }, email) {
    //   return new Promise((resolve, reject) => {
    //     if (email) {
    //       getUserInfo(email).then(res => {
    //         if (res && res.data) {
    //           const userInfo = { ...res.data }
    //           user.actions.setUser({ commit }, userInfo)
    //           resolve(userInfo)
    //         } else {
    //           user.actions.setUser({ commit }, null)
    //           resolve()
    //         }
    //       }).catch(err => {
    //         user.actions.setUser({ commit }, null)
    //         reject(err)
    //       })
    //     } else {
    //       user.actions.setUser({ commit }, null)
    //       resolve()
    //     }
    //   })
    // },
    // setUser({ commit }, userInfo) {
    //   commit('SET_USER', userInfo)
    // },
    setRegisterEmail({ commit }, registerEmail) {
      commit('SET_REGISTER_EMAIL', registerEmail)
    },
    setPassword({ commit }, password) {
      commit('SET_PASSWORD', password)
    }
  },
}

export default user
