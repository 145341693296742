const tokenKey = 'csmsadmin.accessToken'
const expiredKey = 'csmsadmin.expiredTime'
const gigyaTokenKey = 'gigya.JWTToken'
const appTokenKey = 'app.JWTToken'
const XAUTHTokenKey = 'xauth.Token'
const curUserAd = 'CurUserAd'
const tokenstatus = 'tokenstatus'
// const { getEnvConfig } = require('@/settings')
const PUBLIC_PATH = '/csms/portal';
const PROXY_SERVER_URL = 'https://apps-tst.sha.roche.com/CSMSGigya';
const { getEnvConfig } = require('@/settings')
const { localUrl} = getEnvConfig()

export function isTokenExpired() {
  const expiredTime = getExpiredTime() 
  if (expiredTime && expiredTime > new Date().getTime() + 30 * 60 * 1000) {
    return false
  } else {
    clearCache()
    return true
  }
}

export function getCodeFromUrl() {
  return new URLSearchParams(window.location.search).get('code')
}

// access token
export function getAccessToken() {
  return localStorage.getItem(tokenKey)
}

export function setAccessToken(accessToken) {
  return localStorage.setItem(tokenKey, accessToken)
}

export function removeAccessToken() {
  return localStorage.removeItem(tokenKey)
}
//gigya token
export function getGigyaToken() {
  return localStorage.getItem(gigyaTokenKey)
}

export function setGigyaToken(token) {
  return localStorage.setItem(gigyaTokenKey, token)
}

export function removeGigyaToken() {
  return localStorage.removeItem(gigyaTokenKey)
}
//XAUTHTokenKey
export function getXAUTHToken() {
  return localStorage.getItem(XAUTHTokenKey)
}

export function setXAUTHToken(token) {
  return localStorage.setItem(XAUTHTokenKey, token)
}

export function removeXAUTHToken() {
  return localStorage.removeItem(XAUTHTokenKey)
}

//app token 
export function getAppToken() {

  return localStorage.getItem(appTokenKey)
}

export function setAppToken(appToken) {
  return localStorage.setItem(appTokenKey, appToken)
}

export function removeAppToken() {
  return localStorage.removeItem(appTokenKey)
}

export function getExpiredTime() {
  return localStorage.getItem(expiredKey)
}

export function setExpiredTime(expiredTime) {
  return localStorage.setItem(expiredKey, expiredTime)
}

export function removeExpiredTime() {
  return localStorage.removeItem(expiredKey)
}

export function getcurUserAd() {
  return localStorage.getItem(curUserAd)
}

export function setcurUserAd(ad) {
  return localStorage.setItem(curUserAd, ad)
}

export function removecurUserAd() {
  return localStorage.removeItem(curUserAd)
}

export function setstatus(status) {
  return localStorage.setItem(tokenstatus, status)
}

export function getstatus() {
  return localStorage.getItem(tokenstatus)
}

export function removestatus() {
  return localStorage.removeItem(tokenstatus)
}
/*
export function redirectToLogin() {
  window.location.href = `${SSO_URL}?appid=${APP_ID}&appSecret=${APP_SECRET}&redirect_uri=${getLocalUrl()}`
}

export function logOut() {
  clearCache()
  redirectToLogin()
}*/


// 重定向->登录页面
export function redirectToLogin() {
  // window.location.href = `${VUE_APP_SSO_URL}?appid=${VUE_APP_ID}&appSecret=${VUE_APP_SECRET}&redirect_uri=${getLocalUrl()}`
  gigya.accounts.showScreenSet({
    screenSet: 'Default-RegistrationLogin'
  })
  // gigya.sso.login(
  //       {
  //           authFlow: 'redirect',
  //           redirectURL: localUrl,
  //       });

}

export function logOut() {
  gigya.accounts.logout({
    callback: function(response) {
      if (response.errorCode == 0) {
        clearCache()
        location.href = PROXY_SERVER_URL+PUBLIC_PATH
      } else {
        console.log('登出错误')
      }
    },
    forceProvidersLogout: true
  })
}

export function clearCache() { 
  removeGigyaToken()
  removeXAUTHToken()
  removeExpiredTime()
  removecurUserAd()
}

function getUrlKey(name) {
  return (
    decodeURIComponent(
      (new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(
        location.href
      ) || ['', ''])[1].replace(/\+/g, '%20')
    ) || null
  )
}

// 移除url中的code查询参数
export function getLocalUrl() {
  const codestr = 'code=' + getUrlKey('code')
  if (window.location.href.indexOf(`?${codestr}`) >= 0) {
    // 匹配?code=xxxxx
    if (window.location.href.indexOf(`?${codestr}&`) >= 0) {
      // 匹配?code=xxxxx&
      return window.location.href.replace(`?${codestr}&`, '?')
    }
    return window.location.href.replace(`?${codestr}`, '') // 匹配?code=xxxxx
  } else {
    // 匹配?xxxxx&code=xxxxx
    return window.location.href.replace(`&${codestr}`, '')
  }
}


