<template>
  <div>
    <a href="javascript:;" data-fancybox-close class="m-close">
      <span class="fancybox-close-text" title="关闭">{{ $t('Fancbox.close') }}</span>
    </a>
    <div class="modal-inner">
      <h2 v-if="lists.headers" class="m-header" data-i18n="Hiring.head">
        {{ $t(lists.headers) }}
      </h2>
      <div class="m-body">
        <a-spin tip="正在请求链接...请稍后" :spinning="loading">
          <ul class="list-btn">
            <template v-if="myListsLinks.length > 0" v-for="item in myListsLinks">
              <li style="position:relative">
                <a :id="item.itemid" target="_blank" @click.stop="onClick(item.url, item.itemid, item.type,$event)">
                  {{ $t(item.title) }}
                </a>
                <!-- <Lottie v-if="item.isNew" :options="defaultOptions" class="myTag2" :width="60" :height="60" /> -->
                <a-tag color="#f50" class="myTag2" v-if="item.isNew" :style="{ backgroundColor: 'transparent', color: '#f00', fontWeight: '700', textShadow: '-1px -1px 0 yellow, 1px -1px 0 yellow, -1px 1px 0 yellow, 1px 1px 0 yellow',fontSize:'2vh' }">
                  New
                </a-tag>
                <a-tag color="orange" class="myTag" title="新员工培训"
                  v-if="isNeedTraining() && isFinishTraining(item.itemid)">
                  {{ $t('Training.waitingTraining') }} 
                </a-tag>
                <a-tag color="green" title="新员工培训" class="myTag"
                  v-if="isNeedTraining() && isNotFinishTraining(item.itemid)">
                  {{ $t('Training.completeTraining') }}
                </a-tag>
                <!-- <img src="../../assets/img/unread.png" class="myTag" height="24"> -->
              </li>
            </template>
          </ul>
        </a-spin>
      </div>
    </div>
  </div>
</template>

<script>
import { accesslog, downloadFile } from '@/api'

import Lottie from 'vue-lottie/src/lottie.vue'
import * as anData from '@/assets/lottie/new.json'
import { mapGetters } from 'vuex'
import { isMobile } from '@/utils/util.js'
import $ from '@/plugins/jquery.vendor'
export default {
  name: "fancyBox",
  data() {
    return {
      isVpnflag: false,
      defaultMime: "application/octet-stream",
      myTrainList: [],
      defaultOptions: {
        animationData: anData.default
      }

    }
  },
  components: {
    Lottie
  },
  computed: {
    ...mapGetters([
      'loading'
    ]),
    myListsLinks(){
      if(this.lists && this.lists.links && this.lists.links.length > 0){
        return this.sortNewLists(this.lists.links)
      }
    },
    isMyMobile(){
      return isMobile()
    }
  },
  props: {
    lists: {
      type: Object,
      default: () => { return {} }
    },
    trainList: {
      type: Array,
      default: () => { return [] }
    }
  },

  watch: {
    lists: {
      immediate: true,
      deep: true,
      handler(val) {
        let that = this;
        if (val?.links?.length > 0) {
          if (this.myTrainList.length > 0) {
            let ids = val.links.map(item => item.itemid)
            that.checkTrainInList(this.myTrainList, ids)
          }
        }
      }
    },
    trainList: {
      immediate: true,
      deep: true,
      handler(val) {
        let that = this;
        if (val.length > 0) {
          console.log("test")
          this.myTrainList = val[0].children
          // console.log("children", this.myTrainList)
          if (that.lists?.links?.length > 0) {
            if (this.myTrainList.length > 0) {
              let ids = that.lists.links.map(item => item.itemid)
              // console.log("this.myTrainList", this.myTrainList)
              // console.log("that.lists", that.lists.links)
              let trainListIds = that.myTrainList.map(item => item.id)
              that.lists.links = that.sortLists(that.lists.links, trainListIds)
              that.checkTrainInList(this.myTrainList, ids)
            }else{
              console.log(this.myTrainList)
            }
          }
        }
      }
    }
  },
  mounted() { },
  methods: {
    sortLists(arr, ids) {
      // 创建一个新数组来存储排序后的结果  
      let sortedArr = [];

      // 将ids数组中的元素按照在arr中的出现顺序移到sortedArr的前面  
      for (let id of ids) {
        let foundItem = arr.find(item => item.itemid == id);
        if (foundItem) {
          sortedArr.push(foundItem);
          // 从原始数组中移除已排序的元素（可选，如果你不想改变原始数组）  
          // arr = arr.filter(item => item.itemid !== id);  
        }
      }
      let arrCopy = [...arr];
      // 遍历sortedArr中的每个元素，并从arrCopy中移除它  
      for (let item of sortedArr) {
        arrCopy = arrCopy.filter(existingItem => existingItem.itemid !== item.itemid);
      }
      // 第三步：将arrCopy中剩余的元素（即不在ids中的元素）添加到sortedArr的末尾  
      sortedArr = sortedArr.concat(arrCopy);

      return sortedArr
    },
    sortNewLists(arr) {
      // 分离出isNew为true的项  
      let isNewTrueArr = arr.filter(item => item.isNew == true);
      if(isNewTrueArr.length > 0){
          // 剩余的是isNew为false或没有isNew属性的项  
        let isNewFalseArr = arr.filter(item => item.isNew != true);
        // 将两部分合并，isNew为true的项在前面  
        let sortedArr = isNewTrueArr.concat(isNewFalseArr);
        return sortedArr
      }
      return arr
    },
    checkTrainInList(myTrainList, ids) {
      let that = this
      myTrainList.map(item => {
        let valueExists = ids.includes(Number(item.id));
        if (!valueExists) {
          // console.log("培训列表的项目已不存在当前地图中", item.pid + item.id + item.name)
          that.$parent.deleteNotExistsTrainItems(item.id, item.pid);
        }
      })
    },
    FinishTraining(itemid) {
      let arr = this.myTrainList.filter(item => item.id == itemid)
      if (arr.length > 0) {
        arr[0].isfinish = true
        this.$emit('changeTrainList', itemid)
      }
    },
    isNeedTraining() {
      return this.myTrainList.length > 0 ? true : false
    },
    isFinishTraining(itemid) {
      let arr = this.myTrainList.filter(item => (item.id == itemid && item.isfinish == false))
      return arr.length > 0 ? true : false
    },
    isNotFinishTraining(itemid) {
      let arr = this.myTrainList.filter(item => (item.id == itemid && item.isfinish == true))
      return arr.length > 0 ? true : false
    },
    onClick(url, itemid, type,event) {
      console.log("点击链接",event)
      event.stopPropagation()
      let that = this;
      let copyUrl = this.$i18n.tc(url)
      let accessdate = this.$moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
      let category = ""
      let index = url.indexOf(".");
      category = url.substring(0, index);
      let type2 = this.$i18n.tc(type)
      if (copyUrl && copyUrl !== url) {
        // alert("资源缺省，请上传对应资源")
        //type:1:外部链接，2:本地上传 3：内部链接
        if (type2 == "3") {
          this.isVpnflag = true;
          this.$nextTick(() => {
            that.$confirm({
              title: that.$i18n.tc('LinksFor.title'),
              content: that.$i18n.tc('LinksFor.message'),
              okText: that.$i18n.tc('LinksFor.okText'),
              cancelText: that.$i18n.tc('LinksFor.cancelText'),
              onOk() {
                if (!window.open(copyUrl, '_blank')) { // popup blocked, offer direct download:
                  if (confirm("Displaying New Document\n\nUse Save As... to download, then click back to return to this page.")) { location.href = copyUrl; }
                }
                // if(confirm("Displaying New Document\n\nUse Save As... to download, then click back to return to this page.")){ location.href=copyUrl; }      
                //do iframe dataURL download (old ch+FF):
                // var f = document.createElement("iframe");
                // document.body.appendChild(f);

                // if(/^data:/.test(copyUrl)){ // force a mime that will download:
                //   copyUrl="data:"+url.replace(/^data:([\w\/\-\+]+)/, that.defaultMime);
                // }
                // f.src=copyUrl;
                // setTimeout(function(){ document.body.removeChild(f); }, 333);        
              },
              onCancel() { },
              zIndex: 99999
            });
          })
        } else if (type2 == "2") {
          downloadFile(itemid)
        } else {
          if (!window.open(copyUrl, '_blank')) { // popup blocked, offer direct download:
            if (confirm("Displaying New Document\n\nUse Save As... to download, then click back to return to this page.")) { location.href = copyUrl; }
          }
        }
      } else {
        if (category === "Bus" && itemid === 208) {
          setTimeout(() => {
            $.fancybox.open(  
              $("#drivingLicense"), {
              modal: true,
            }
          )
          $("#drivingLicense .fancybox-close-small").hide()
          }, 100);
          that.$emit('openDrivingLicense')
        } else {
          if (!that.$i18n.te(url)) {
            let i18n = that.$i18n.locale
            let lang = i18n == "en" ? "zh" : "en"
            if (that.$i18n.te(url, lang)) {
              let u = that.$i18n.t(url, lang)
              let t = that.$i18n.t(type, lang)
              if (t == "3") {
                this.isVpnflag = true;
                this.$nextTick(() => {
                  that.$confirm({
                    title: that.$i18n.tc('LinksFor.title'),
                    content: that.$i18n.tc('LinksFor.message'),
                    okText: that.$i18n.tc('LinksFor.okText'),
                    cancelText: that.$i18n.tc('LinksFor.cancelText'),
                    onOk() {
                      if (!window.open(u)) { // popup blocked, offer direct download:
                        if (confirm("Displaying New Document\n\nUse Save As... to download, then click back to return to this page.")) { location.href = u; }
                      }
                    },
                    onCancel() { },
                    zIndex: 99999
                  });
                })
              } else if (t == "2") {
                downloadFile(itemid)
              } else {
                if (!window.open(u, '_blank')) { // popup blocked, offer direct download:
                  if (confirm("Displaying New Document\n\nUse Save As... to download, then click back to return to this page.")) { location.href = u; }
                }
              }
            } else {
              alert("中英文资源缺省，请上传对应资源")
            }
          }
        }
      }

      that.FinishTraining(itemid)
      accesslog({ accessdate, category, itemid }).then(res => {
        // console.log(res);
      })
    }
  }
}
</script>
<style scoped>
#vpnAlert {
  display: none;
}

.myTag2 {
  position: absolute;
  left: -1vh;
  top: 1vh;
  transform: translateY(-50%);
  /* opacity: 0.8; **/
}

.myTag {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}
</style>