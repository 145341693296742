import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home.vue'

Vue.use(VueRouter)



const routes = [
  // {
  //   path: '/home',
  //   name: 'home',
  //   component: Home,
  // },
  {
    path: '/',
    redirect: '/home',
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/home'),
    hidden: true,
  },
  {
    path:'/personcredit',
    name:'personcredit',
    component:()=>import('@/views/personalCredit/index.vue'),
    hidden:true
  },
  {
    path:'/more',
    name:'more',
    component:()=>import('@/views/personalCredit/more.vue'),
    hidden:true
  }
]


const router = new VueRouter({
  routes
})

export default router
